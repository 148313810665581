import { useContext, useState, useEffect } from 'react'
import { Input, Avatar, Paper, Box, Typography, Divider } from '@mui/material';

import List from '@mui/material/List';

import ConversationInput from './conversation-input'
import ConversationHeader from './conversation-header';
import ConversationMessage from './conversation-message'
import ConversationContext from '../context/ConversationContext';

import ReactLoading from "react-loading";

type ConversationHeaderProps = {
    setButtonClicked: (value: boolean) => void;
    setArrowBackButtonClicked: (value: boolean) => void;
    setHeaderBackButtonClicked: (value: boolean) => void;
};

const ConversationContainer = ({ setButtonClicked, setArrowBackButtonClicked, setHeaderBackButtonClicked }: ConversationHeaderProps) => {
    const { selectedSenderId, network }: any = useContext(ConversationContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    // const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='conversations-container' >
            {
                !isMobile && network == false &&
                <Paper className='conversation-container-paper-1' >
                    <ReactLoading
                        type="spinningBubbles"
                        color="rgba(2, 189, 101, 1)"
                        height={100}
                        width={50}
                    />
                    <br />
                    <Typography>Reconnecting to the server... Please wait</Typography>
                </Paper>
            }
            {!isMobile && network == true && selectedSenderId == '' &&
                <Paper className='conversation-container-paper-2'>
                    <Typography variant='h5'>SELECT A CONVERSATION</Typography>
                </Paper>
            }
            {network == true && selectedSenderId !== '' &&
                <Paper className='conversation-container-paper-3' >
                    <ConversationHeader setButtonClicked={setButtonClicked} setArrowBackButtonClicked={setArrowBackButtonClicked} setHeaderBackButtonClicked={setHeaderBackButtonClicked} />
                    <Divider />
                    <ConversationMessage
                    />
                    <Divider />
                    <ConversationInput />
                </Paper>
            }
        </div>
    )
}

export default ConversationContainer