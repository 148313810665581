import {
    useState,
    useEffect,
    useContext
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    makeStyles,
    Box,
    InputAdornment,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Stack,
    FormControl,
    MenuItem,
    InputLabel
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import ConversationContext from '../context/ConversationContext';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import SearchIcon from '@mui/icons-material/Search';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import { BACKEND_URL } from '../constants'

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//         marginTop: theme.spacing(3),
//     },
//     conversations: {
//         padding: theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         height: '85vh',
//         width: '80%',
//         overflow: 'auto',
//     },
//     noConversation: {
//         padding: theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         height: '5vh',
//         width: '100%',
//     }
// }))

const ConversationToolbar = () => {
    const { filterValue, setFilterValue, filterBy, setFilterBy, botChannel, setBotChannel, filterTag, setFilterTag, filterID, setFilterID, filter, setFilter, selectedSenderId }: any = useContext(ConversationContext)
    const [remarks, setRemarks] = useState([]);
    const [remarkTag, setRemarkTag] = useState('');

    useEffect(() => {
        const fetchTagsChoices = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/fetch-tags-choices/60126023473`);
            const data = await response.json();
            setRemarks(data.tags);
          } catch (error) {
            console.error("Failed to fetch note from backend:", error);
          }
        };
        fetchTagsChoices();
      }, [selectedSenderId]);

    const handleTagChange = (event: SelectChangeEvent) => {
        setFilterTag(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleRemarkChange = (event: SelectChangeEvent) => {
        setRemarkTag(event.target.value);
    };

    const handleIdChange = (event: any) => {
        setFilterID(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setFilterBy(event.target.value);
        if (event.target.value === 'null') {
            setBotChannel('')
            setFilterTag('')
            setFilterID('')
            setFilterValue('');
        }
    };

    const handleBotChannel = (
        event: React.MouseEvent<HTMLElement>,
        botChannel: string | null,
    ) => {
        setBotChannel(botChannel);
        setFilterValue(botChannel);
    };

    return (
        <div style={{ backgroundColor: '#f0f2f5', height: '8%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <IconButton onClick={() => {setFilter(!filter); setFilterBy(''); setFilterTag('');}}>
                <ManageSearchOutlinedIcon/>
                </IconButton>
                { filterBy === 'campus' &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterTag}
                    onChange={handleTagChange}
                    label="Tag"
                    >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'112508211735810'}>Bangsar South</MenuItem>
                    {/* <MenuItem value={'106366842382598'}>Kuching</MenuItem>
                    <MenuItem value={''}>Springhill</MenuItem>
                    <MenuItem value={''}>College</MenuItem> */}
                    </Select>
                </FormControl>
                }
                { filter === true && filterBy === 'id' &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100, maxWidth: 120 }}>
                    <InputLabel htmlFor="input-with-icon-adornment">
                    Search By ID
                    </InputLabel>
                    <Input
                    id="input-with-icon-adornment"
                    value={filterID}
                    onChange={handleIdChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <PersonSearchIcon fontSize='small' />
                        </InputAdornment>
                    }
                    />
                </FormControl>
                }
                { filter === true && filterBy === 'tag' &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterTag}
                    onChange={handleTagChange}
                    label="Tag"
                    >
                    <MenuItem value={'Online'}>Open</MenuItem>
                    <MenuItem value={'Fallback'}>Fallback</MenuItem>
                    <MenuItem value={'Call In'}>Incoming</MenuItem>
                    <MenuItem value={'Chatting'}>Active</MenuItem>
                    </Select>
                </FormControl>
                }
                { filter === true && filterBy === 'remark' &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="tag-select-standard-label">Tag</InputLabel>
                    <Select
                    labelId="tag-select-standard-label"
                    id="tag-select-standard"
                    value={remarkTag}
                    onChange={handleRemarkChange}
                    label="Remark"
                    >
                    {remarks && remarks.length > 0 && remarks.map((remark: any) => (
                        <MenuItem
                        key={remark}
                        value={remark}
                        >
                        {remark}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                }
                { filter === true &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="demo-simple-select-standard-label">Filter By</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterBy}
                    onChange={handleChange}
                    label="Filter"
                    >
                    <MenuItem value={'null'}>
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={'campus'}>Campus</MenuItem>
                    <MenuItem value={'id'}>ID</MenuItem>
                    <MenuItem value={'tag'}>Status</MenuItem>
                    <MenuItem value={'remark'}>Tags</MenuItem>
                    </Select>
                </FormControl>
                }
        </div>
    )
}

export default ConversationToolbar

