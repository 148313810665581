import { 
    CreateButton,
    Datagrid,
    EditButton,
    FilterButton, 
    List, 
    Pagination,
    ShowButton,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useState, cloneElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


// Filters for the User listing
const UserFilter = [
    <TextInput source="email"/>,
    <TextInput source="fullname"/>,
    <TextInput source="username"/>
]

// Paginator for the User listing
const UserPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({permissions}: {permissions: any}) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
)

const UserList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    return (
        <List actions={<ListActions permissions={permissions}/>} filters={UserFilter} title={translate('resources.user.form.list')} bulkActionButtons={false} exporter={false} pagination={<UserPaginator/>}>
            <Datagrid>
                {/* <TextField label="id" source="id"/> */}
                <TextField label={translate('resources.user.fields.username')} source="username"/>
                <TextField label={translate('resources.user.fields.fullname')} source="fullname"/>
                <TextField label={translate('resources.user.fields.email')} source="email"/>
                <ShowButton />
                {permissions ? permissions.includes("USER:WRITE") && <EditButton /> : ""}
            </Datagrid>
        </List>
    )
}

export default UserList;