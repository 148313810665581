import {
    useEditController,
    useNotify,
    useRedirect,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    CheckboxGroupInput,
    usePermissions
} from 'react-admin';

import Button from '@mui/material/Button';
import { useState, cloneElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dataProvider from '../data-provider'
import { APP_PERMISSIONS } from '../constants'

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({permissions,...props}: any) => {
    const redirect = useRedirect()

    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            <Button variant="contained" onClick={() => redirect('/roles')} color="inherit" startIcon={<ArrowBackIcon />}>
                Back
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    
        return (
            <div>
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                    {/* <EditButton basePath = {props.basePath} record={props.data} /> */}
                </TopToolbar>
            </div>
            
        )
}

const RoleShow = ({...props}) => { 
    const translate = useTranslate()
    const {record} = useEditController(props)
    const [permissionChoices, setPermissionChoices]: any[] = useState([])
    const notify = useNotify()
    const { permissions } = usePermissions();
    // Initial the Role choices only once
    useEffect(() => {
        dataProvider.getMany('fetch-permission-choices', { ids: [] })
            .then(({ data }) => {
                setPermissionChoices(data);
            })
            .catch(err => {
                notify(err.message)
            })
    }, []);
    return (
        <Show title={translate('resources.role.form.show')} 
            {...props}
            actions={<ListActions />} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <BooleanInput variant="outlined" source="enabled"
                    disabled
                    label={translate('resources.role.fields.enabled')}
                    fullWidth/>
                <br/>
                <TextInput variant="outlined" source="role_name"
                    disabled
                    label={translate('resources.role.fields.name')} 
                    fullWidth/>
                
                {permissionChoices.map(function(object: any, index: any){
                    return    <CheckboxGroupInput key={index} label={object.module} source="permissions" choices={object.permissions} optionValue="id" optionText="name" disabled/>
                })}

            </SimpleForm>
        </Show>
    )
}

export default RoleShow