import {
    useTranslate,
    ShowButton,
    useListContext,
    TopToolbar,
    usePermissions,
    List,
    Datagrid,
    TextField,
    Pagination,
} from 'react-admin'
import Button from '@mui/material/Button';
import { cloneElement } from 'react'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';

const PromotionPaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props} />

const ListActions = ({ ...props }) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext()

    const navigate = useNavigate()
    return (
        <div>
            <TopToolbar>
                {
                    <Button variant="text" onClick={() => navigate('/template/create')} startIcon={<AddIcon />}>
                        Create
                    </Button>
                }
                {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            </TopToolbar>
        </div>
    )
}


const BroadcastList = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    return (
        <List actions={<ListActions permissions={permissions} />} title={translate('resources.template.form.list')} bulkActionButtons={false} exporter={false} pagination={<PromotionPaginator />}>
            <Datagrid>
                <TextField label={translate('resources.template.fields.template_name')} source="template_name" />
                <TextField label={translate('resources.template.fields.category')} source="category" />

                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default BroadcastList