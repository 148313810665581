import * as React from "react";
import PropTypes from 'prop-types';

const CustomBooleanField = ({ source, record = {} }) => <span>{record[source] ? 'Yes' : 'No' }</span>;

CustomBooleanField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
CustomBooleanField.defaultProps = {
    addLabel: true
}
export default CustomBooleanField;