import {
    useEditController,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    TextField,
    SelectField,
    usePermissions,
    AutocompleteInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            <Button variant="contained" onClick={() => navigate("/prediction")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}

const PredictionShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.prediction.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <TextInput source="text"
                    disabled
                    label={translate('resources.prediction.fields.text')}
                    helperText={translate('resources.prediction.helperText.text')}
                    fullWidth />
                <TextInput source="predicted_intent"
                    disabled
                    label={translate('resources.prediction.fields.predicted_intent')}
                    helperText={translate('resources.prediction.helperText.predicted_intent')}
                    fullWidth multiline />
                <TextInput source="correct_intent"
                    disabled
                    label={translate('resources.prediction.fields.correct_intent')}
                    helperText={translate('resources.prediction.helperText.correct_intent')}
                    fullWidth
                />
            </SimpleForm>
        </Show>
    )
}

export default PredictionShow;
