import {
    useEffect,
    useContext
} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ConversationContext from '../../context/ConversationContext';
const RequestDashboard = () => {
    const { selectedSenderId, chats }: any = useContext(ConversationContext)

    const getRowsByTag = (tag: any) => {
        return chats?.filter((conversation: any) => conversation.conversation_tag === tag);
    };
    
    return (
        <TableContainer component={Paper} style={{ backgroundColor: '#f0f2f5' }}>
        <Table>
            <TableBody>
            <TableRow>
                <TableCell>Chatting</TableCell>
                <TableCell>
                {getRowsByTag('Chatting')?.length}
                </TableCell>
                <TableCell>Online</TableCell>
                <TableCell>
                {getRowsByTag('Online')?.length}
                </TableCell>
            </TableRow>
            <TableRow>  
                <TableCell>Call In</TableCell>
                <TableCell>
                {getRowsByTag('Call In')?.length}
                </TableCell>
                <TableCell>Fallback</TableCell>
                <TableCell>
                {getRowsByTag('Fallback')?.length}
                </TableCell>
            </TableRow>
            <TableRow>  
                <TableCell>Handover</TableCell>
                <TableCell>
                {getRowsByTag('Handover')?.length}
                </TableCell>
                <TableCell>Denied</TableCell>
                <TableCell>
                {getRowsByTag('Denied')?.length}
                </TableCell>
            </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
    );
};

export default RequestDashboard;
