import { 
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

// Paginator for the Response listing
const WorkingHourPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({permissions}: {permissions: any}) => (
    <TopToolbar>
        {/* <FilterButton/>
        <CreateButton/> */}
    </TopToolbar>
)

const WorkingHourList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;
    return (
        <List actions={<ListActions permissions={permissions}/>} title={translate('resources.working_hour.form.list')} bulkActionButtons={false} exporter={false} pagination={<WorkingHourPaginator/>}>
            <Datagrid>
                <TextField label={translate('resources.working_hour.fields.day_of_week')} source="day_of_week"/>
                <TextField label={translate('resources.working_hour.fields.start_hour')} source="start_hour"/>
                <TextField label={translate('resources.working_hour.fields.start_minute')} source="start_minute"/>
                <TextField label={translate('resources.working_hour.fields.end_hour')} source="end_hour"/>
                <TextField label={translate('resources.working_hour.fields.end_minute')} source="end_minute"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default WorkingHourList;