import {
    useEditController,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    TextField,
    SelectField,
    usePermissions,
    DateInput
} from 'react-admin';
import Button from '@mui/material/Button';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            {/* <EditButton basePath = {props.basePath} record={props.data} /> */}
            <Button variant="contained" onClick={() => navigate("/live-agent-working-hour")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}

const WorkingHourShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.working_hour.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <TextInput source="day_of_week"
                    disabled
                    label={translate('resources.working_hour.fields.day_of_week')}
                    helperText={translate('resources.working_hour.helperText.day_of_week')}
                />
                <TextInput source="start_hour"
                    disabled
                    label={translate('resources.working_hour.fields.start_hour')}
                    helperText={translate('resources.working_hour.helperText.start_hour')}
                />
                <TextInput source="start_minute"
                    disabled
                    label={translate('resources.working_hour.fields.start_minute')}
                    helperText={translate('resources.working_hour.helperText.start_minute')}
                />
                <TextInput source="end_hour"
                    disabled
                    label={translate('resources.working_hour.fields.end_hour')}
                    helperText={translate('resources.working_hour.helperText.end_hour')}
                />
                <TextInput source="end_minute"
                    disabled
                    label={translate('resources.working_hour.fields.end_minute')}
                    helperText={translate('resources.working_hour.helperText.end_minute')}
                />
            </SimpleForm>
        </Show>
    )
}

export default WorkingHourShow;