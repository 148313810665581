import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RoleCreate from './role-create';
import RoleEdit from './role-edit';
import RoleList from './role-list'
import RoleShow from './role-show';

export default {
    edit: RoleEdit,
    show: RoleShow,
    list: RoleList,
    create: RoleCreate,
    icon: ManageAccountsIcon,
}