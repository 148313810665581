import { createContext, useState, useEffect, useRef, useReducer, useMemo } from "react";
//import axios from 'axios';
import { ActionReducer } from '../reducers/reducers';
import { WEBSOCKET_URL, BACKEND_URL } from '../constants';
import { combine2Validators } from "ra-core";
import axios from 'axios';

const InternalConversationContext = createContext({});

export const DataProvider = ({ children }: {children: any}) => {
    const [selectedSenderId, setSelectedSenderId] = useState('')
    const [selectedRoomId, setSelectedRoomId] = useState('')
    const [selectedAgentId, setSelectedAgentId] = useState(null)
    const [selectedBotChannel, setSelectedRBotChannel] = useState('')
    const [selectedConversationTag, setSelectedConversationTag]: any = useState(null)
    const [chats, setConversationsList] = useState(null)
    const [messages, setMessages]: any[] = useState([])
    const [unreadMessages, setUnreadMessages]: any[] = useState([])
    const [targetId, setTargetId] = useState(null)
    const [inputDisabled, setInputDisabled] = useState(true)
    const listref = useRef(false)
    const [isPaused, setPause] = useState(false);
    const wsRef = useRef<null | WebSocket>(null); 
    const [wsConn, setWsConn] = useState<null | WebSocket>(null); 
    const [onIntercept, setOnIntercept] = useState(false)
    const [result, dispatch] = useState('')
    const [data, setData] = useReducer(ActionReducer, '')
    const [universityId, setUniversityId] = useState(1)
    const [agentId, setAgentId] = useState(null)
    const [agentInfo, setAgentInfo]: any = useState(null)
    const [loadAgent, setLoadAgent]: any = useState(false)
    const [pendingRequest, setPendingRequest]: any = useState(false)
    const [agentIsHandler, setAgentIsHandler]: any = useState(false)
    const [loadAgentPermission, setLoadAgentPermission]: any = useState(false)
    const [handoverAgentId, setHandoverAgentId]: any = useState(null)
    const [note, setNote] = useState("");
    const [transferButtonClicked, setTransferButtonClicked]: any = useState(false)
    const [selectedCreatedAt, setSelectedCreatedAt]: any = useState(null)
    const [selectedSessionStatus, setSelectedSessionStatus]: any = useState('Online')
    const [receiveHandover, setReceiveHandover]: any = useState(false)
    const [names, setNames] = useState<string[]>([]);
    const [tagName, setTagName] = useState<string[]>([]);
    const [botChannel, setBotChannel] = useState<string | null>('');
    const [filterBy, setFilterBy] = useState('');
    const [filterTag, setFilterTag] = useState('');
    const [filterID, setFilterID] = useState('');
    const [filter, setFilter] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [filterChannel, setFilterChannel] = useState('');
    const [selectedUniversityId, setSelectedUniversityId] = useState(null);
    const [socketioConnection, setSocketioConnection] = useState(true);
    const [userConnection, setUserConnection] = useState(true);

    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')

    const loadAgentInfo = async () => {
        const response = await fetch(`${BACKEND_URL}/live-agent/${agentId}`, {
            method: "GET"
        })

        const data = await response.json();
        console.log(data)
        setAgentInfo(data);
        setLoadAgent(true)
    }

    useEffect(() => {
        if (!agentId) return;
        console.log("loading agent info")
        loadAgentInfo();
    }, [agentId]);

    const loadData = async () => {
        const response = await fetch(`${BACKEND_URL}/fetch-agents-choices`, {
            method: "GET"
        })
        const data = await response.json();
        console.log(data)
        setConversationsList(data);
    }

    const loadFilteredData = async () => {
        console.log(filterBy)
        console.log(filterValue)
        const response = await fetch(`${BACKEND_URL}/conversations?filter=${filterBy}&value=${filterValue}`, {
            method: "GET"
        })
        const data = await response.json();
        console.log(data.result)
        setConversationsList(data.result);
    }

    const loadChannelData = async () => {
        console.log(filterBy)
        console.log(filterValue)
        const response = await fetch(`${BACKEND_URL}/conversations?channel=${filterChannel}&filter=${filterBy}&value=${filterValue}`, {
            method: "GET"
        })
        const data = await response.json();
        console.log(data.result)
        setConversationsList(data.result);
    }

    useEffect(() => {
        loadData();
    }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         console.log("filterChannel: " + filterChannel)
    //         console.log(filterBy)
    //         if (filterChannel){
    //             loadChannelData();
    //         } else if (filterBy) {
    //             loadFilteredData();
    //         } else {
    //             loadData();
    //         }
    //     }, 1000)
    //     return () => clearInterval(interval)
    // }, [chats]);

// START WEB SOCKET
    // avoid creating multiple websocket
    const [network, setNetwork] = useState(true)

    useEffect( () => {
        if (network == false) {
            console.log("No connection")
            const interval = setInterval(() => {
                connect();
            }, 5000)
            return () => clearInterval(interval)
        } 
    }, [network])

    function connect() {
        wsRef.current = new WebSocket(`${WEBSOCKET_URL}/internal-chat/`);

        wsRef.current.onopen = function() {
            console.log('Connection Opened')
            setNetwork(true);
        };
        wsRef.current.onclose = function() {
            setNetwork(false);
            console.log('Connection closed')
        };

        const wsCurrent = wsRef.current;

        return () => {
            wsCurrent.close();
        };
    }

    useEffect(() => {
        connect();
    }, []);



    //handle websocket send and receive
    useEffect(() => {
        if (!wsRef.current) return; 

        // if (wsRef.current && result && result !== '') {
        //     wsRef.current.send(JSON.stringify(result))
        //     dispatch('')
        // }

        wsRef.current.onmessage = e => {
            if (isPaused) return;
            const data = JSON.parse(e.data);
            // setData(getData)
            console.log("e", data);
            console.log("a", data.action);
            if (data.action === 'conversation' && data.target_id === selectedSenderId) {
                setMessages(data.content)
                setTargetId(data.target_id)
                setSocketioConnection(data.on_session)
            } else if (data.action === 'conversation' && data.target_id !== selectedSenderId) {
                setUnreadMessages(data.content)
                setTargetId(data.target_id)
            } else if (data.action === 'intercept' && data.target_id === selectedSenderId && data.agent_id !== agent_id) {
                console.log("THIS")
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
            } else if (data.action === 'intercept' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                console.log("this")
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
            } else if (data.action === 'end_intercept' && data.target_id === selectedSenderId) {
                setOnIntercept(false)
                setAgentId(null)
                setSelectedAgentId(null)
                setAgentIsHandler(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false && data.content.on_pending && data.agent_id === agent_id) {
                setOnIntercept(false)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false && data.content.on_pending && data.agent_id !== agent_id) {
                setOnIntercept(false)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === false) {
                setOnIntercept(false)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.content.on_handover && data.agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.handover_agent_id)
                setAgentIsHandler(true)
                setPendingRequest(true)
                setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.content.on_handover && data.agent_id !== agent_id && data.handover_agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(true)
                setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true && data.agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
                setSocketioConnection(data.content.on_session)
            } else if (data.action === 'listen' && data.target_id === selectedSenderId && data.content.on_intercept === true) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setSocketioConnection(data.content.on_session)
            } else if (data.action === 'handover' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                console.log(data.handover_agent_id)
                setOnIntercept(true)
                setPendingRequest(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.agent_id)
            } else if (data.action === 'handover' && data.target_id === selectedSenderId && data.handover_agent_id === agent_id) {
                console.log(data.handover_agent_id)
                setOnIntercept(true)
                setPendingRequest(false)
                setReceiveHandover(true)
                setAgentId(data.handover_agent_id)
                setSelectedAgentId(data.agent_id)
            } else if (data.action === 'accept_handover' && data.target_id === selectedSenderId && data.handover_agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setAgentIsHandler(true)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(false)
            } else if (data.action === 'accept_handover' && data.target_id === selectedSenderId && data.handover_agent_id !== agent_id) {
                setOnIntercept(true)
                setAgentId(data.handover_agent_id)
                setAgentIsHandler(false)
                setSelectedAgentId(data.handover_agent_id)
                setReceiveHandover(false)
            } else if (data.action === 'deny_handover' && data.target_id === selectedSenderId && data.agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(true)
                setPendingRequest(false)
            } else if (data.action === 'deny_handover' && data.target_id === selectedSenderId && data.agent_id !== agent_id && data.handover_agent_id === agent_id) {
                setOnIntercept(true)
                setAgentId(data.agent_id)
                setSelectedAgentId(data.agent_id)
                setAgentIsHandler(false)
                setReceiveHandover(false)
            }
        };
    }, [isPaused, onIntercept, selectedSenderId]);

    useEffect(() => {
        if (onIntercept === false){
            setInputDisabled(true)
        } else if (onIntercept === true){
            setInputDisabled(false)
        }
    }, [onIntercept]);

    // Get Messages by selected sender ID
    useEffect(() => {
        if (selectedSenderId){
            axios
            .get(`${BACKEND_URL}/conversation-by-id/${selectedSenderId}`)
            .then((res: any) => setMessages(res.data))
            .catch((err: any) => { console.warn(err) })
        }

    }, [selectedSenderId]);

    useEffect(() => {
        // reinitialize all state on sender id change
        setAgentId(null)
        setUniversityId(1)
        setAgentInfo(null)
        setTransferButtonClicked(false)
        setAgentIsHandler(false)
        setNote("")
        setSocketioConnection(true)
        setUserConnection(true)
    }, [selectedSenderId]);

    return (
        <InternalConversationContext.Provider value={{
            // contains all the shared states needed across the conversation module
            chats, selectedSenderId, setSelectedSenderId,
            selectedConversationTag, setSelectedConversationTag,
            selectedAgentId, setSelectedAgentId,
            messages, targetId, inputDisabled,
            result, dispatch, wsRef,
            onIntercept, unreadMessages, network,
            selectedRoomId, setSelectedRoomId,
            selectedBotChannel, setSelectedRBotChannel,
            universityId, setUniversityId,
            agentId, setAgentId,
            agentInfo, setAgentInfo,
            transferButtonClicked, setTransferButtonClicked,
            pendingRequest, agentIsHandler, setAgentIsHandler,
            loadAgent, setLoadAgent, 
            loadAgentPermission, setLoadAgentPermission,
            selectedCreatedAt, setSelectedCreatedAt,
            handoverAgentId, setHandoverAgentId,
            selectedSessionStatus, setSelectedSessionStatus,
            receiveHandover, setReceiveHandover,
            note, setNote,
            names, setNames,
            tagName, setTagName,
            filterBy, setFilterBy, 
            botChannel, setBotChannel,
            filterTag, setFilterTag, 
            filterID, setFilterID, 
            filter, setFilter,
            filterValue, setFilterValue,
            filterChannel, setFilterChannel,
            selectedUniversityId, setSelectedUniversityId,
            socketioConnection, setSocketioConnection,
            userConnection, setUserConnection
        }}>
            {children}
        </InternalConversationContext.Provider>
    )
}

export default InternalConversationContext;
