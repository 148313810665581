import {
    useState,
    useContext,
    useEffect
} from 'react'
import {
    InputBase,
    OutlinedInput,
    IconButton,
    Stack,
    Typography,
    Button,
    Box
} from '@mui/material';
import axios from 'axios';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import ConversationContext from '../context/ConversationContext';
//import axios from 'axios';
import { BACKEND_URL } from '../constants';
import FormData from "form-data";

const ConversationInput = () => {
    const [text, setText] = useState('');
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const sender_name = sessionStorage.getItem('fullname')

    const { onIntercept, selectedAgentId, targetId, inputDisabled, wsRef, selectedSenderId, selectedRoomId, selectedBotChannel, agentId, socketioConnection, userConnection, setUserConnection, agentMessage, setAgentMessage }: any = useContext(ConversationContext)
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [minRows, setMinRows] = useState(1);



    const sendAttachment = (event: any, selectedFile: any, selectedSenderId: any) => {
        const formData = new FormData();
        formData.append("upload_file", selectedFile);
        formData.append("sender_id", selectedSenderId);
        formData.append("room_id", selectedRoomId);
        formData.append("bot_channel", selectedBotChannel);
        formData.append("action", 'live_agent');
        const token = sessionStorage.getItem('token')

        if (token && selectedFile && selectedSenderId) {
            axios
                .post(`${BACKEND_URL}/post-attachment`,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                )
                .then(res => console.log(res))
                .catch(err => console.warn(err));
        }
        setIsFilePicked(false);
        setSelectedFile(null)
    }

    const addMessage = (newData: any) => {
        setAgentMessage((prevState: any[]) => [...prevState, newData]);
    };

    const currentTimestamp = new Date().getTime();

    const sendInputMessage = (event: any) => {
        if (text !== "") {
            addMessage({ 'sender_id': selectedSenderId, 'bot_channel': selectedBotChannel, 'action': 'live_agent', 'message': '👤: ' + text, 'status': 3, 'created_at': currentTimestamp, sender_name: sender_name });
            // setAgentMessage(agentMessage.push({'sender_id': selectedSenderId, 'bot_channel': selectedBotChannel, 'action': 'user', 'message': text, 'status': 3}))
            wsRef.current.send(JSON.stringify({ action: 'message', target_id: selectedSenderId, source_id: identity, text: text, room_id: selectedRoomId, bot_channel: selectedBotChannel, sender_name: sender_name }))
            setText("")
            setMinRows(1)
        } else {
            setText("")
        }
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files ? event.target.files[0] : null);
        setIsFilePicked(true);
    };


    useEffect(() => {
        if (socketioConnection === true) return;

        if (selectedBotChannel !== 'webchat') return;

        setUserConnection(false);
    }, [socketioConnection, selectedSenderId]);

    return (
        <div className='conversation-input' >
            <>
                {isFilePicked === true &&
                    <Stack sx={{ m: 2 }} spacing={1} direction="row" alignItems="center" justifyContent="flex-start">
                        <Typography variant="caption" sx={{ color: 'grey[200]' }}>{selectedFile && selectedFile.name ? selectedFile.name : null}</Typography>
                        <Button sx={{ color: 'red' }} variant="text" onClick={(e) => { setSelectedFile(null); setIsFilePicked(false) }}>x</Button>
                    </Stack>
                }
                {isFilePicked === false &&
                    <Box alignItems='center' sx={{ m: 2, width: '80%', backgroundColor: 'white' }}>
                        {/* <TextArea
                                disabled={(selectedAgentId !== agent_id) || onIntercept === false}
                                autoFocus
                                value={text}
                                multiline
                                rows={minRows}
                                onChange={e => setText(e.target.value)}
                                onKeyDown={(event: any) => {
                                if (event.key === 'Enter' && event.shiftKey) {
                                    event.preventDefault();
                                    setMinRows(minRows + 1);
                                } else if (event.key === 'Enter') {
                                    sendInputMessage(event);
                                    sendAttachment(event, selectedFile, selectedSenderId);
                                }
                                }}
                                style={{
                                minHeight: '15%',
                                width: '100%',
                                overflow: 'auto',
                                resize: 'none',
                                border: 'none', 
                                wordWrap: 'break-word', 
                                overflowWrap: 'break-word'
                                }}
                                placeholder="   Type something..."
                        /> */}
                        <InputBase
                            disabled={(selectedAgentId !== agent_id) || onIntercept === false}
                            inputProps={{ autoFocus: true }}
                            value={text}
                            multiline
                            minRows={1}
                            // rows={minRows}
                            maxRows={3}
                            onChange={e => setText(e.target.value)}
                            sx={{
                                width: '100%',
                                overflow: 'auto',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                            }}
                            placeholder="   Type something..."
                            onKeyPress={(event) => {
                                if (event.key === 'Enter' && event.shiftKey) {
                                    event.preventDefault();
                                    setText(text + '\n')
                                }

                                if (event.key === 'Enter' && text.trim() !== '' && !event.shiftKey) {
                                    event.preventDefault();
                                    sendInputMessage(event);
                                    sendAttachment(event, selectedFile, selectedSenderId)
                                }
                            }}
                        />
                    </Box>
                }
                {/* {userConnection === false &&
                    <Box alignItems='center' sx={{ m: 2 }}>
                        <Typography variant="body1" sx={{ color: 'red' }}>User has disconnected.</Typography>
                    </Box>
                } */}
                <input accept="image/*" name="pic" id="fileUpload" style={{ width: '0%', visibility: "hidden", overflow: "hidden" }} type="file" multiple={false} onChange={changeHandler} />
                <Stack direction="row" spacing={1}>
                    {/* <IconButton><AddReactionOutlinedIcon color="primary" fontSize='large' /></IconButton> */}
                    <label htmlFor="fileUpload">
                        {isFilePicked === true &&
                            <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} color="primary" component="span">
                                <PostAddOutlinedIcon fontSize='large' />
                            </IconButton>
                        }
                        {isFilePicked === false &&
                            <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} component="span">
                                <PostAddOutlinedIcon fontSize='large' />
                            </IconButton>
                        }
                    </label>
                    <IconButton disabled={(selectedAgentId !== agent_id) || onIntercept === false} color="primary" onClick={(e) => { sendInputMessage(e); sendAttachment(e, selectedFile, selectedSenderId) }}><SendSharpIcon fontSize='large' /></IconButton>
                </Stack></>
        </div>
    )
}

export default ConversationInput
