import PersonIcon from '@mui/icons-material/Person';
import UserCreate from './user-create';
import UserEdit from './user-edit';
import UserList from './user-list';
import UserShow from './user-show';

export default {
    create: UserCreate,
    edit: UserEdit,
    list: UserList,
    show: UserShow,
    icon: PersonIcon,
}