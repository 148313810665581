import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import {
    useState,
    useEffect,
    useContext
} from 'react'
import ConversationContext from '../../context/ConversationContext';
import Stack from '@mui/material/Stack';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 16,
    },
});

export default function SessionStatus() {
    const [remainingTime, setRemainingTime] = useState(0);
    const { selectedCreatedAt, selectedSenderId, selectedSessionStatus, setSelectedSessionStatus }: any = useContext(ConversationContext)
    
    useEffect(() => {
        if (!selectedCreatedAt) return ;
        
        const secondsIn24Hours = 24 * 60 * 60; // number of seconds in 24 hours
        const futureEpochTime = selectedCreatedAt + secondsIn24Hours; // epoch time after 24 hours
        const targetTime = new Date(futureEpochTime * 1000); // convert epoch time to date object
        const currentTime = new Date();
        const difference = targetTime.getTime() - currentTime.getTime();
        setRemainingTime(difference);
    }, [selectedSenderId]);
    
    useEffect(() => {
        if (!selectedCreatedAt) return ;
        const interval = setInterval(() => {
            const secondsIn24Hours = 24 * 60 * 60; // number of seconds in 24 hours
            const futureEpochTime = selectedCreatedAt + secondsIn24Hours; // epoch time after 24 hours
            const targetTime = new Date(futureEpochTime * 1000); // convert epoch time to date object
            const currentTime = new Date();
            const difference = targetTime.getTime() - currentTime.getTime();
            setRemainingTime(difference);
        }, 1000);
        return () => clearInterval(interval);
    }, [selectedSenderId]);

    function getStatus(time: any) {
        const hours = Math.floor(time / 1000 / 60 / 60) % 24;

        if (hours >= 16) {
            return "Open"
        } else if (hours > 0 && hours < 16) {
            return "Expiring"
        } else {
            return "Expired"
        }
    }

    function getColor(time: any) {
        const hours = Math.floor(time / 1000 / 60 / 60) % 24;
        
        if (hours >= (24-16)) {
            return "#2196F3"
        } else if (hours > 0 && hours < (24-16)) {
            return "#FFA500"
        } else {
            return "#FF0000"
        }
    }


    return (
        <ThemeProvider theme={theme}>
            <Stack spacing={4} direction="row" >
                <Typography style={{color: '#6c757d'}} variant="body2">Session </Typography>
                <Typography style={{color: getColor(remainingTime)}} variant="body2">{selectedSessionStatus}</Typography>
            </Stack>
        </ThemeProvider>
    );
}