import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    createContext,
    useContext,
    useReducer,
    useMemo,
    useRef
} from 'react'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import ConversationContext from '../context/ConversationContext';
import BadgeAvatars from './components/badge-avatar';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import { AnyNsRecord } from 'dns';

const ConversationItem = ({ key, handleConversationClick, conversationIndex, sender_id, created_at, conversation_tag, bot_channel, user_message_count, room_id, agent_id, clock_color, session_status,time_epoch, university_id, agent_name, status }: any) => {
    // const { unreadMessages }: any = useContext(ConversationContext)
    console.log(agent_name)
    const [userMessageCount, setUserMessageCount]: any = useState(0)
    const [newMessage, setNewMessage]: any = useState(false)
    const userMessageCountRef = useRef(user_message_count)

    useEffect(() => {
        if (user_message_count > userMessageCountRef.current) {
            setNewMessage(true)
        }
    }, [user_message_count]);

    function resetCounts() {
        setUserMessageCount(0)
        userMessageCountRef.current = user_message_count
    }

    function getSenderId(sender_id: string) {
        try {
            return sender_id.substring(0,14)
        } catch {
            return sender_id
        }
        
    }

    function getStatus(status: number) {
        if (status === 1) {
            return "Online"
        } else {
            return "Offline"
        }
    }

    function getDifference(a: number, b: number) {
        return Math.abs(a-b);
    }

    useEffect(() => {
        if (newMessage == true) {
            let a: number = user_message_count
            let b: number = userMessageCountRef.current
            const difference = getDifference(a, b)
            setUserMessageCount(difference)
            setNewMessage(false)
        }
    }, [newMessage, userMessageCount]);

    // useEffect(() => {
    //     console.log(msgCountRef.current)
    //     setUserMessageCount(msgCountRef.current)
    // }, [user_message_count]);

    return (
        <ListItem alignItems="center" button onClick={(event) => {handleConversationClick(event, conversationIndex, sender_id, bot_channel, room_id, agent_id, conversation_tag, created_at, session_status, time_epoch, university_id, agent_name, status); resetCounts()}}>
            <ListItemAvatar>
                <BadgeAvatars color={clock_color}/>
            </ListItemAvatar>
            <ListItemText
                // primary={sender_id}
                // secondary={<Typography component={'span'} variant={'body2'}>{created_at} {conversation_tag}</Typography>}
                // secondary={<Fragment><Typography>{created_at}</Typography><Typography>{conversation_tag}</Typography></Fragment>}  
                primary = {<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1' }}>
                <p style={{ width: '20vh'}}>{agent_name}</p>
                { userMessageCount > 0 && <Box style={{display: 'flex', width: '20px', height: '20px', backgroundColor: 'rgba(2, 189, 101, 1)', borderRadius: '50%'}}><a style={{margin: 'auto', color: 'white'}}>{userMessageCount}</a></Box>}
                </div></>}
                secondary={<><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', lineHeight: '0.1' }}>
                    <p style={{ width: '20vh' }}>{created_at}</p>
                    <a>{getStatus(status)}</a>
                </div></>}
            />
        </ListItem>

    )
}

const ConversationList = () => {
    const { chats, selectedSenderId, setSelectedConversationTag, setSelectedSenderId, dispatch, wsRef, targetId, setSelectedRoomId, setSelectedRBotChannel,setSelectedAgentId, setSelectedCreatedAt, selectedSessionStatus, setSelectedSessionStatus, setSelectedUniversityId}: any = useContext(ConversationContext)
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    // const [selectedSenderId, setSelectedSenderId] = useState('')
    // const [result, dispatch] = useReducer(ActionReducer, '')
    console.log(chats)
    const handleConversationClick = (event: any, conversationIndex: any, sender_id: any, bot_channel: any, room_id: any, agent_id: any, conversation_tag: any, created_at: any, session_status: any, time_epoch: any, university_id: any) => {
        setSelectedSenderId(sender_id)
        setSelectedRoomId(room_id)
        setSelectedRBotChannel(bot_channel)
        setSelectedAgentId(agent_id)
        setSelectedConversationTag(conversation_tag)
        setSelectedCreatedAt(time_epoch)
        setSelectedSessionStatus(session_status)
        setSelectedUniversityId(university_id)
        wsRef.current.send(JSON.stringify({ action: 'listen', source_id: identity, target_id: sender_id, bot_channel: bot_channel}))
    }

    // remove task from backend if user no longer listening to the coming target Id
    useEffect(() => {
        // if (!wsRef.current) return; 
        if (targetId && targetId !== selectedSenderId){ 
            wsRef.current.send(JSON.stringify({action: 'remove', source_id: identity, target_id: targetId}))
        }
    }, [selectedSenderId]);

    return (
        <><div style={{ height: '92%', overflowX: 'hidden', overflowY: 'auto', display: 'inline-block', verticalAlign: 'middle' }}>
            <Box>
            {chats && chats.length > 0 && chats.map((chat: any) => (
                    <List dense>
                        <ConversationItem
                            key={chat.id}
                            created_at={chat.created_at}
                            conversation_tag={chat.conversation_tag}
                            sender_id={chat.sender_id}
                            handleConversationClick={handleConversationClick}
                            user_message_count={chat.user_message_count}
                            room_id={chat.room_id}
                            bot_channel={chat.bot_channel}
                            agent_id={chat.agent_id}
                            clock_color={chat.clock_color}
                            session_status={chat.session_status}
                            time_epoch={chat.time_epoch}
                            university_id={chat.university_id}
                            agent_name={chat.agent_name}
                            status={chat.status}
                        />
                        <Divider variant="inset" component="li" />
                        {/* {i + 1 < chats.length && <Divider variant="inset" component="li" />} */}
                    </List>
            ))
            }
            </Box>
        </div>
        </>
    )
}

export default ConversationList