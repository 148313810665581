import { 
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    useNotify,
    usePermissions,
    useTranslate,
    Button,
} from 'react-admin';

import { BACKEND_URL } from '../constants';
import { useState, useEffect } from 'react';

// Filters for the AuditTrail listing
const AuditTrailFilter = [
    <TextInput source="user"/>,
    <TextInput source="action"/>,
    <TextInput source="category"/>,
    <TextInput source="description"/>,
]


// Paginator for the AuditTrail listing
const AuditTrailPagination = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props}/>

const AuditTrailList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    const ListActions = ({permissions}: {permissions: any}) => (
        <TopToolbar>
            <FilterButton/>
        </TopToolbar>
    )
    return (
        <List sort={{field: 'created_at', order: 'DESC'}} actions={<ListActions permissions={permissions}/>} filters={AuditTrailFilter} title={translate('resources.audit_trail.form.list')} exporter={false} pagination={<AuditTrailPagination/>} perPage={15}>
            <Datagrid>
                <TextField label={translate('resources.audit_trail.fields.created_at')} source="created_at"/>
                <TextField label={translate('resources.audit_trail.fields.user')} source="user"/>
                <TextField label={translate('resources.audit_trail.fields.action')} source="action"/>
                <TextField label={translate('resources.audit_trail.fields.category')} source="category"/>
                <TextField label={translate('resources.audit_trail.fields.description')} source="description"/>
            </Datagrid>
        </List>
    )
}

export default AuditTrailList;