import ScheduleIcon from '@mui/icons-material/Schedule';
import WorkingHourList from './working-hour-list';
import WorkingHourShow from './working-hour-show';
import WorkingHourEdit from './working-hour-edit';
import WorkingHourCreate from './working-hour-create';

export default {
    // create: WorkingHourCreate,
    list: WorkingHourList,
    show: WorkingHourShow,
    edit: WorkingHourEdit,
    icon: ScheduleIcon,
}