import * as React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { useRecordContext } from 'react-admin';

const CustomDateField = ({ source, showTime }) => {
    const record = useRecordContext();

    return (
        <span>{showTime ? moment(record[source]).format("yyyy-MM-DD hh:mm:ss A") : moment(record[source]).format("yyyy-MM-DD")}</span>
    )
}

CustomDateField.propTypes = {
    showTime: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
CustomDateField.defaultProps = {
    addLabel: true,
    showTime: false,
}

export default CustomDateField