import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useReducer,
    useMemo,
    useRef,
    createContext
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles
} from '@mui/material';
import {
    Title
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ConversationInput from './conversation-input'
import ConversationHeader from './conversation-header';
import ConversationMessage from './conversation-message'
import ConversationToolbar from './conversation-toolbar'
import ConversationList from './conversation-list'
import ConversationSidebar from './conversation-sidebar';
import ConversationContainer from './conversation-container';
import { DataProvider } from '../context/InternalConversationContext'
import InternalConversationContext from '../context/InternalConversationContext';
import ConversationChannel from './conversation-channel';

const InternalConversationShell = () => {
    // const classes = useStyles()
    const { selectedSenderId }: any = useContext(InternalConversationContext)
    const [selectedConversation, setSelectedConversation] = useState(false)

    useEffect(() => {
        // Avoid useEffect to run twice on mount at the first time
        if (selectedSenderId){
            setSelectedConversation(true)
        }
    }, [selectedSenderId]);

    return (
        <div style={{ height: '95vh', width: '100%', overflow: 'auto' }}>
            <DataProvider>
                <br></br>
                <Paper style={{ height: '95%', maxWidth: 'fit-content', display: 'inline-block', verticalAlign: 'middle' }}>
                    <ConversationToolbar/>
                    <Divider />
                    <ConversationList/>
                </Paper>
                <ConversationContainer/>
            </DataProvider>
        </div>
    )
}

export default InternalConversationShell