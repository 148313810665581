
import EventIcon from '@mui/icons-material/Event';
import HolidayList from './holiday-list';
import HolidayShow from './holiday-show';
import HolidayEdit from './holiday-edit';
import HolidayCreate from './holiday-create';

export default {
    create: HolidayCreate,
    list: HolidayList,
    show: HolidayShow,
    edit: HolidayEdit,
    icon: EventIcon,
}