import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { useGetList, Edit, Toolbar, ShowButton, DeleteButton, SaveButton, useTranslate, TopToolbar, sanitizeListRestProps, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength } from 'react-admin';
import dataProvider from '../data-provider'
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Async function to fetch User Role list
 * 
 * @param {*} notify 
 */

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const navigate = useNavigate()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        </TopToolbar>

    )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine} />
            <Button variant="contained" onClick={() => navigate(`/prediction`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton />
        </Toolbar>
    )
}


const PredictionEdit = ({ ...props }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [intentList, setIntentList] = useState<any[]>([]);

    const { data, isLoading } = useGetList(
        'intent',
        {
            pagination: { page: 1, perPage: 1000 }, 
            sort: { field: 'name', order: 'ASC' } 
        },
    );

    useEffect(() => {
        if (!isLoading && data) {
            setIntentList(data);
        }
    }, [data, isLoading]);

    const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}%` });


    const validateText = [required(translate('resources.prediction.errors.text.required'))]
    const validateCorrectIntent = [required(translate('resources.prediction.errors.correct_intent.required'))]

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        delete data['id']
        return {
            ...data
        }
    }

    return (
        <Edit title={translate('resources.prediction.form.edit')}
            transform={transformRecord}
            actions={<ListActions />}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />}  >
                <br />
                <TextInput variant="outlined" source="text"
                    validate={validateText}
                    resettable={true}
                    label={translate('resources.prediction.fields.text')}
                    helperText={translate('resources.prediction.helperText.text')}
                    fullWidth
                />
                <TextInput variant="outlined" source="predicted_intent"
                    resettable={true}
                    label={translate('resources.prediction.fields.predicted_intent')}
                    helperText={translate('resources.prediction.helperText.predicted_intent')}
                    disabled
                    fullWidth
                />
                <AutocompleteInput source="correct_intent"
                    validate={validateCorrectIntent}
                    label={translate('resources.prediction.fields.correct_intent')}
                    helperText={translate('resources.prediction.helperText.correct_intent')}
                    fullWidth
                    isLoading={isLoading}
                    choices={intentList} 
                    filterToQuery={filterToQuery}
                />
            </SimpleForm>
        </Edit>
    )
}

export default PredictionEdit
