import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';

import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRedirect, useRefresh, SimpleForm, SelectInput, TextInput, required } from 'react-admin';

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({basePath}: any, {...props}) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine}/>
            <Button variant="contained" onClick={() => navigate('/bot-responses')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const ResponseCreate = (props: any) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();

    /**
     * Create Form submission failure handling
     * @param {} param0 
     */

    const onSuccess = ({ data }: any) => {
        notify(`Bot Response "${data.response_code}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
      };

    const onCreateFailure = (error: any) => {
        if (typeof error === 'string') {
            notify(error)
        } else if (error.message)
            notify(error.message)
        else
            notify('ra.notification.http_error')
            redirect('/bot-response');
            refresh();
    }

    const validateResponseName = [
        required(translate('resources.response.errors.response_name.required'))
    ]
    const validateLanguage = [required(translate('resources.response.errors.language.required'))
    ]
    const validateUtterance = [
        required(translate('resources.response.errors.utterance.required'))
    ]
    const validateChannel = [
        required(translate('resources.response.errors.input_channel.required'))
    ]
    
    return (
        <Create title={translate('resources.response.form.create')} onSuccess={onSuccess} onFailure={onCreateFailure}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />}  >
                <br/>
                <TextInput variant="outlined" source="response_code"
                    validate={validateResponseName}
                    resettable={true}
                    label={translate('resources.response.fields.response_code')} 
                    helperText={translate('resources.response.helperText.response_code')} 
                    fullWidth/>
                <SelectInput variant="outlined" source="bot_language"
                    validate={validateLanguage}
                    resettable={true}
                    label={translate('resources.response.fields.bot_language')} 
                    helperText={translate('resources.response.helperText.bot_language')} 
                    choices={[
                        {id: "en", name: "English"}, 
                        {id: 'my', name: "Bahasa Malaysia"},
                        {id: "zh", name: "Chinese"}
                    ]}/>
                <TextInput variant="outlined" source="content"
                    validate={validateUtterance}
                    resettable={true}
                    label={translate('resources.response.fields.content')} 
                    helperText={translate('resources.response.helperText.content')} 
                    fullWidth multiline/>
                <SelectInput variant="outlined" source="bot_channel"
                    validate={validateChannel}
                    resettable={true}
                    label={translate('resources.response.fields.bot_channel')} 
                    helperText={translate('resources.response.helperText.bot_channel')} 
                    choices={[
                        {id: "wa", name: "WhatsApp"}, 
                        {id: 'fb', name: "Facebook"},
                        {id: "webchat", name: "Webchat"}
                    ]}/>
                {/* <TextInput variant="outlined" source="remark"
                    resettable={true}
                    label={translate('resources.response.fields.remark')} 
                    helperText={translate('resources.response.helperText.remark')} 
                    fullWidth/> */}
            </SimpleForm>
        </Create>
    )
}

export default ResponseCreate