import { 
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    useNotify,
    usePermissions,
    useTranslate,
    Button,
    EditButton,
    FunctionField
} from 'react-admin';
import {useEffect, useState} from "react"

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';


import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';

import { BACKEND_URL } from '../constants';

import axios from 'axios';

// Filters for the Prediction listing
const PredictionFilter = [
    <TextInput source="text"/>,
    <TextInput source="correct_intent"/>,
    <TextInput source="predicted_intent"/>,
]

const downloadCSV = async () => {
    const apiUrl = `${BACKEND_URL}/prediction/export`;
    fetch(apiUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'predictions.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
        )
}


// Paginator for the Prediction listing
const PredictionPaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props}/>


const PredictionList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;
    const notify = useNotify();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverClose = () => {
        setIsPopoverOpen(false);
    };

    const selectFile = () => {
        setIsPopoverOpen(true);
    };

    const handleImport = (event: any) => {
        importCsv(event.target.files[0])
        setIsPopoverOpen(false);
    };

    const importCsv = (selectedFile: any) => {
        const formData = new FormData();
        const token = sessionStorage.getItem('token')
        formData.append('upload_file', selectedFile);

        if (token && selectedFile) {
            formData.append('file', selectedFile)
            axios
                .post(`${BACKEND_URL}/prediction/import`,
                        formData,
                        {headers: { 
                            'Content-Type': 'multipart/form-data', 
                            'Authorization': `Bearer ${token}` 
                        }}
                )
                .then(res => notify('Predictions Updated'))
                .catch(err => notify(`Predictions Update Failed: ${err}`));
        }
    }

    const formatDateTime = (record: any) => {
        const date = new Date(record.created_at);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        // minutes = String(minutes).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
    };
      

    const ListActions = ({permissions}: {permissions: any}) => (
        <TopToolbar>
            <Button
                label="Import"
                startIcon={<AddIcon />}
                onClick={selectFile}
            />
            <Button 
                label="Export" 
                onClick={downloadCSV} 
                startIcon={<DownloadIcon />}
            />
            <FilterButton/>
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                >
                <Box
                    sx={{
                        width: '300px', // Adjust the width as needed
                        height: '100px', // Adjust the height as needed
                        padding: '16px',
                    }}
                >
                    <Stack direction="row" display="flex" justifyContent="space-between">
                        <Typography>
                            Select a file to import:
                        </Typography>
                        <Button
                            label="x"
                            onClick={handlePopoverClose}
                        />
                    </Stack>
                    <br/>
                    <br/>
                    <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        name="pic"
                        id="fileUpload"
                        type="file"
                        multiple={false}
                        onChange={handleImport}
                    />
                </Box>
            </Popover>
        </TopToolbar>
    )
    return (
        <List sort={{field: 'id', order: 'DESC'}} actions={<ListActions permissions={permissions}/>} filters={PredictionFilter} title={translate('resources.prediction.form.list')} exporter={false} pagination={<PredictionPaginator/>} perPage={15}>
            <Datagrid style={{tableLayout: 'fixed'}}>
                <TextField label={translate('resources.prediction.fields.created_at')} source="created_at"/>
                <TextField style={{overflowWrap: 'break-word'}} label={translate('resources.prediction.fields.text')} source="text"/>
                <TextField style={{overflowWrap: 'break-word'}} label={translate('resources.prediction.fields.predicted_intent')} source="predicted_intent"/>
                <TextField label={translate('resources.prediction.fields.confidence')} source="confidence"/>
                <TextField style={{overflowWrap: 'break-word'}} label={translate('resources.prediction.fields.correct_intent')} source="correct_intent"/>
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default PredictionList;

