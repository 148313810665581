import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, TextInput } from 'react-admin';

const AppointmentActionField = ({ source, label} ) => {
    const record = useRecordContext();

    return (
        <TextInput disabled label={label} source={source} defaultValue={
            record[source] == 1 ? 'New' : 
            record[source] == 2 ? 'Updated' :
            record[source] == 3 ? 'Cancelled' : 'unknown'}/>
    )
}

{/* <span>{
    record[source] == 1 ? 'New' : 
    record[source] == 2 ? 'Updated' :
    record[source] == 3 ? 'Cancelled' : 'unknown'}</span>; */}

AppointmentActionField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
AppointmentActionField.defaultProps = {
    addLabel: true
}

export default AppointmentActionField;