import BroadcastList from "./broadcast-list"
import BroadcastCreate from "./broadcast-create"
import BroadcastShow from "./broadcast-show"
import FileCopyIcon from '@mui/icons-material/FileCopy';

export default {
    list: BroadcastList,
    create: BroadcastCreate,
    show: BroadcastShow,
    icon: FileCopyIcon
}