import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useReducer,
    createContext
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles,
    Box,
    InputAdornment,
    Stack,
    IconButton,
    Button
} from '@mui/material';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SearchIcon from '@mui/icons-material/Search';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CallIcon from '@mui/icons-material/Call';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ConversationContext from '../context/ConversationContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatIcon from '@mui/icons-material/Chat';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SvgIcon } from '@mui/material';

type ConversationHeaderProps = {
    setButtonClicked: (value: boolean) => void;
    setArrowBackButtonClicked: (value: boolean) => void;
    setHeaderBackButtonClicked: (value: boolean) => void;
};

const ConversationHeader = ({ setButtonClicked, setArrowBackButtonClicked, setHeaderBackButtonClicked }: ConversationHeaderProps) => {
    const { requestChat, setRequestChat, selectedSessionStatus, selectedAgentId, agentId, wsRef, inputDisabled, selectedRoomId, selectedBotChannel, selectedConversationTag, selectedUniversityId }: any = useContext(ConversationContext)
    const { selectedSenderId, setSelectedSenderId }: any = useContext(ConversationContext)
    const identity = sessionStorage.getItem('identity')
    const agent_id = sessionStorage.getItem('agent_id')
    const university_id = sessionStorage.getItem('university_id')?.toString()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleClick = () => {
        setButtonClicked(true);
        setArrowBackButtonClicked(false);
    };

    const handleHeaderBackClick = () => {
        setHeaderBackButtonClicked(true);
        if (selectedSenderId !== '') {
            setSelectedSenderId('');
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleRequestChat = () => {
        wsRef.current.send(JSON.stringify({ action: 'request_chat', target_id: selectedSenderId, source_id: identity, room_id: selectedRoomId, bot_channel: selectedBotChannel, agent_id: agent_id, university_id: university_id }))
        setRequestChat(true)
    }

    const handleCallIconClick = () => {
        wsRef.current.send(JSON.stringify({ action: 'intercept', target_id: selectedSenderId, source_id: identity, room_id: selectedRoomId, bot_channel: selectedBotChannel, agent_id: agent_id, university_id: university_id }))
    }

    const handleCallEndIconClick = () => {
        wsRef.current.send(JSON.stringify({ action: 'end_intercept', target_id: selectedSenderId, source_id: identity, room_id: selectedRoomId, bot_channel: selectedBotChannel, agent_id: agent_id }))
    }

    function getCampusName() {
        if (selectedRoomId === '39037210652') {
            return "Kuala Lumpur Campus"
        } else if (selectedRoomId === '106366842382598') {
            return "Kuching Campus"
        }
    }

    return (
        <div className='conversation-header' >
            {isMobile && (
                <>
                    <IconButton id='handle-botton' onClick={handleHeaderBackClick}><ArrowBackIosIcon /></IconButton>
                    <IconButton id='handle-botton' onClick={handleClick}><InfoIcon /></IconButton>
                </>

            )}
            <div className='spacer'></div>
            {selectedSessionStatus === 'Expired' &&
                <Button style={{ marginRight: '10px' }} color="success" variant="contained" disabled={requestChat} onClick={(event) => handleRequestChat()}>CALL OUT</Button>
            }
            {selectedSessionStatus !== 'Expired' && agent_id !== 'null' && inputDisabled === false &&
                <Button style={{ marginRight: '10px' }} color="error" variant="contained" disabled={(selectedAgentId !== agent_id)} onClick={(event) => handleCallEndIconClick()}>END CHAT</Button>
            }
            {selectedSessionStatus !== 'Expired' && selectedConversationTag === 'Call In' && selectedAgentId && inputDisabled === true &&
                <Button style={{ marginRight: '10px' }} color="success" variant="contained" disabled={(selectedAgentId !== agent_id) && (agent_id !== selectedUniversityId)} onClick={(event) => handleCallIconClick()}>ANSWER</Button>
            }
            {selectedSessionStatus !== 'Expired' && (selectedConversationTag !== 'Call In' && selectedConversationTag !== 'Disconnected') && selectedAgentId && inputDisabled === true &&
                <Button style={{ marginRight: '10px' }} color="success" variant="contained" disabled={(selectedAgentId !== agent_id) && (agent_id !== selectedUniversityId)} onClick={(event) => handleCallIconClick()}>START CHAT</Button>
            }
            {selectedSessionStatus !== 'Expired' && (selectedConversationTag !== 'Call In' && selectedConversationTag !== 'Disconnected') && !selectedAgentId && inputDisabled === true &&
                <Button style={{ marginRight: '10px' }} color="success" variant="contained" onClick={(event) => handleCallIconClick()}>START CHAT</Button>
            }
        </div>
    )
}

export default ConversationHeader