import { 
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

// Filters for the Response listing
const HolidayFilter = [
    <TextInput source="date_of_holiday"/>,
    <TextInput source="description"/>
]

// Paginator for the Response listing
const HolidayPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({permissions}: {permissions: any}) => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
    </TopToolbar>
)

const HolidayList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;
    return (
        <List actions={<ListActions permissions={permissions}/>} filters={HolidayFilter} title={translate('resources.holiday.form.list')} bulkActionButtons={false} exporter={false} pagination={<HolidayPaginator/>}>
            <Datagrid>
                <TextField label="id" source="id"/>
                <TextField label={translate('resources.holiday.fields.date_of_holiday')} source="date_of_holiday"/>
                <TextField label={translate('resources.holiday.fields.description')} source="description"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default HolidayList;