import React, {useCallback, useState} from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { useInput } from 'react-admin'
import {DatePicker, LocalizationProvider} from '@mui/lab'

import DateFnsUtils from '@date-io/date-fns'
import { set } from "date-fns";

const CustomDatePicker = ({source, ...props}) => {
    const {input, meta} = useInput({source})
    const handleDateChange = useCallback(value => {
            if (Date.parse(value)) {
                input.onChange(value.toISOString()) 
                setSelectedDate(value)
            } else {
                input.onChange(null)
                setSelectedDate(null)
            }
        }, [])
    const [selectedDate, setSelectedDate] = useState(
            props.initDate ? Date.parse(props.initDate) :
                input.value ? Date.parse(input.value): null
        )
    
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <DatePicker
                {...props}
                value={selectedDate}
                onChange={handleDateChange}
                label={props.label}
                format={props.format} />
        </LocalizationProvider>
    )
}

export default CustomDatePicker