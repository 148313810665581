import {
    useState,
    useEffect,
    useContext
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    makeStyles,
    Box,
    InputAdornment,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Stack,
    FormControl,
    MenuItem,
    InputLabel
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import ConversationContext from '../context/ConversationContext';
import MultipleSelect from './components/multiple-select';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import SearchIcon from '@mui/icons-material/Search';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import CreateIcon from '@mui/icons-material/Create';

import { BACKEND_URL } from '../constants'

// const useStyles = makeStyles(theme => ({
//     root: {
//         flexGrow: 1,
//         marginTop: theme.spacing(3),
//     },
//     conversations: {
//         padding: theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         height: '85vh',
//         width: '80%',
//         overflow: 'auto',
//     },
//     noConversation: {
//         padding: theme.spacing(1),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//         height: '5vh',
//         width: '100%',
//     }
// }))

const ConversationToolbar = () => {
    const { filterValue, setFilterValue, filterBy, setFilterBy, botChannel, setBotChannel, filterTag, setFilterTag, filterID, setFilterID, filter, setFilter, selectedSenderId, setSubFilter, setSubFilterValue, subFilter, filterName }: any = useContext(ConversationContext)
    const [remarks, setRemarks] = useState([]);
    const [remarkTag, setRemarkTag] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [company, setCompany] = useState('');
    const [department, setDepartment] = useState('');
    const [branch, setBranch] = useState('');

    useEffect(() => {
        const fetchTagsChoices = async () => {
          try {
            const response = await fetch(`${BACKEND_URL}/fetch-tags-choices/60126023473`);
            const data = await response.json();
            setRemarks(data.tags);
          } catch (error) {
            console.error("Failed to fetch note from backend:", error);
          }
        };
        fetchTagsChoices();
      }, [selectedSenderId]);

    const handleTagChange = (event: SelectChangeEvent) => {
        setFilterTag(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleDepartmentChange = (event: SelectChangeEvent) => {
        setDepartment(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleCompanyChange = (event: SelectChangeEvent) => {
        setCompany(event.target.value);
        setFilterBy(event.target.value);
        // setSubFilter(event.target.value);
        // setSubFilterValue(event.target.value);
    };

    const handleCampusChange = (event: SelectChangeEvent) => {
        setBranch(event.target.value)
        setFilterValue(event.target.value);
    };

    const handleRemarkChange = (event: SelectChangeEvent) => {
        setRemarkTag(event.target.value);
        setFilterID(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleIdChange = (event: any) => {
        setFilterID(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleTextChange = (event: any) => {
        setTextSearch(event.target.value);
        setFilterValue(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setFilterBy(event.target.value);
        if (event.target.value === 'null') {
            setBotChannel('')
            setFilterTag('')
            setFilterID('')
            setFilterValue('');
        }
    };

    const handleBotChannel = (
        event: React.MouseEvent<HTMLElement>,
        botChannel: string | null,
    ) => {
        setBotChannel(botChannel);
        setFilterValue(botChannel);
    };

    return (
        <div style={{ backgroundColor: '#f0f2f5', display: 'flex', overflow: 'hidden'}}>
            {
            filter === true && 
            <Stack sx={{ m:1 }} spacing={1} direction="column">
            { filterName.includes('Company') &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100, maxWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Company</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={company}
                    onChange={handleCompanyChange}
                    label="Company"
                    >
                    <MenuItem value={'campus'}>UCSI Education Sdn Bhd</MenuItem>
                    </Select>
                </FormControl>
            }
            { filterName.includes('Department') &&
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100, maxWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Department</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={department}
                    onChange={handleDepartmentChange}
                    label="Company"
                    >
                    <MenuItem value={'student'}>Student Enrolment Centre</MenuItem>
                    </Select>
                </FormControl>
            }
            { filterName.includes('Branch')  &&
            <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="campus-standard-label">Branch</InputLabel>
                <Select
                labelId="campus-standard-label"
                id="campus-select-standard"
                value={branch}
                onChange={handleCampusChange}
                label="Campus"
                >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'112508211735810'}>Kuala Lumpur</MenuItem>
                <MenuItem value={'106366842382598'}>Kuching</MenuItem>
                <MenuItem value={''}>Springhill</MenuItem>
                <MenuItem value={''}>College</MenuItem>
                </Select>
            </FormControl>
            }
            { filterName.includes('ID')  &&
            <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100, maxWidth: 120 }}>
                <InputLabel htmlFor="input-with-icon-adornment">
                Search By ID
                </InputLabel>
                <Input
                id="input-with-icon-adornment"
                value={filterID}
                onChange={handleIdChange}
                startAdornment={
                    <InputAdornment position="start">
                        <PersonSearchIcon fontSize='small' />
                    </InputAdornment>
                }
                />
            </FormControl>
            }
            { filterName.includes('Text')  &&
            <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100, maxWidth: 120 }}>
                <InputLabel htmlFor="input-with-icon-adornment">
                Search By Text
                </InputLabel>
                <Input
                id="input-with-icon-adornment"
                value={textSearch}
                onChange={handleTextChange}
                startAdornment={
                    <InputAdornment position="start">
                        <CreateIcon fontSize='small' />
                    </InputAdornment>
                }
                />
            </FormControl>
            }
            { filterName.includes('Status')  &&
            <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterTag}
                onChange={handleTagChange}
                label="Tag"
                >
                <MenuItem value={'Online'}>Open</MenuItem>
                <MenuItem value={'Fallback'}>Fallback</MenuItem>
                <MenuItem value={'Call In'}>Incoming</MenuItem>
                <MenuItem value={'Chatting'}>Active</MenuItem>
                <MenuItem value={'User Disconnected'}>Disconnected</MenuItem>
                </Select>
            </FormControl>
            }
            { filterName.includes('Tags')  &&
            <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 100 }}>
                <InputLabel id="tag-select-standard-label">Tag</InputLabel>
                <Select
                labelId="tag-select-standard-label"
                id="tag-select-standard"
                value={remarkTag}
                onChange={handleRemarkChange}
                label="Remark"
                >
                {remarks && remarks.length > 0 && remarks.map((remark: any) => (
                    <MenuItem
                    key={remark}
                    value={remark}
                    >
                    {remark}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            }
            </Stack>
            }
        </div>
    )
}

export default ConversationToolbar



