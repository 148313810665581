import { useContext } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import HistoryContext from '../context/HistoryContext';

import { SvgIcon } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ChatIcon from '@mui/icons-material/Chat';

const ConversationChannel = () => {
    const { botChannel, setBotChannel, setFilterChannel, setUseFilter }: any = useContext(HistoryContext)

    const handleBotChannel = (
        event: React.MouseEvent<HTMLElement>,
        botChannel: string | null,
    ) => {
        if (botChannel !== 'all') { 
            setFilterChannel(botChannel);
            setBotChannel(botChannel)
            setUseFilter(true)
        } else {
            setFilterChannel(botChannel);
            setBotChannel(botChannel)
            setUseFilter(false)
        }
    };

    return (
        <div style={{ backgroundColor: '#f0f2f5' }}>
            <ToggleButtonGroup
            value={botChannel}
            exclusive
            fullWidth
            onChange={handleBotChannel}
            aria-label="Bot Channels"
            >
                <ToggleButton size="small" value="all" style={{backgroundColor: botChannel === 'all' ? '#efdfbb' : 'transparent'}} aria-label="inbox">
                    <AllInboxIcon />
                </ToggleButton>
                <ToggleButton size="small" value="wa" style={{backgroundColor: botChannel === 'wa' ? '#efdfbb' : 'transparent'}} aria-label="whatsapp">
                    <SvgIcon style={{ color: '#25D366' }}><WhatsAppIcon/></SvgIcon>
                </ToggleButton>
                <ToggleButton size="small" value="fb" style={{backgroundColor: botChannel === 'fb' ? '#efdfbb' : 'transparent'}} aria-label="facebook">
                    <SvgIcon style={{ color: '#1877F2' }}><FacebookOutlinedIcon/></SvgIcon>
                </ToggleButton>
                <ToggleButton size="small" value="webchat" style={{backgroundColor: botChannel === 'webchat' ? '#efdfbb' : 'transparent'}} aria-label="webchat">
                    <SvgIcon style={{ color: '#2E7D32' }}><ChatIcon/></SvgIcon>
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}

export default ConversationChannel
