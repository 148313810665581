import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useReducer
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles,
    Box,
    InputAdornment,
    Stack,
    IconButton,
    Button
} from '@mui/material';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SearchIcon from '@mui/icons-material/Search';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CallIcon from '@mui/icons-material/Call';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ConversationContext from '../context/ConversationContext';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import ChatIcon from '@mui/icons-material/Chat';
import { SvgIcon } from '@mui/material';

const ConversationHeader = () => {
    return (
        <div style={{ backgroundColor: '#f0f2f5', height: '8%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        </div>
    )
}

export default ConversationHeader