import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';

import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength, TextField, DateInput } from 'react-admin';
import dataProvider from '../data-provider'
import botResponse from "."
import CustomDatePicker from "./components/custom-date-picker";

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))
                                                                                                                              
/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({basePath}: any, {...props}) => {
    const navigate = useNavigate()
    // const classes = useStyles()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine}/>
            <Button variant="contained" onClick={() => navigate('/live-agent-holiday')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const HolidayCreate = (props: any) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
      setSelectedDate(date);
    };
  

    // useEffect( () => {
    //     fetchChannelChoices(notify).then((choices) => setChannelChoices(choices))
    // }, [])
    /**
     * Create Form submission failure handling
     * @param {} param0 
     */

    const onSuccess = ({ data }: any) => {
        notify(`Holiday "${data.response_code}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
      };

    const onCreateFailure = (error: any) => {
        if (typeof error === 'string') {
            notify(error)
        // } else if (error.status == 400) {
        //     for (var key of Object.keys(error.body.messages)) {
        //         error.body.messages[key].forEach( msg => notify(msg))
        //     }
        } else if (error.message)
            notify(error.message)
        else
            notify('ra.notification.http_error')
            redirect('/live-agent-holiday');
            refresh();
    }

    const validateDateOfHoliday = [
        required(translate('resources.holiday.errors.date_of_holiday.required'))
    ]
    const validateDescription = [required(translate('resources.holiday.errors.description.required')),
                minLength(5, translate('resources.holiday.errors.description.minLength')),
                maxLength(100, translate('resources.holiday.errors.description.maxLength'))]

    return (
        <Create title={translate('resources.holiday.form.create')} onSuccess={onSuccess} onFailure={onCreateFailure}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar />}  >
                <br/>
                <DateInput
                    label={translate('resources.holiday.fields.date_of_holiday')}  source="date_of_holiday"/>
                <TextInput variant="outlined" source="description"
                    validate={validateDescription}
                    resettable={true}
                    label={translate('resources.holiday.fields.description')} 
                    helperText={translate('resources.holiday.helperText.description')} 
                    fullWidth/>
            </SimpleForm>
        </Create>
    )
}

export default HolidayCreate