import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import {
  useState,
  useEffect,
  useContext
} from 'react'
import ConversationContext from '../../context/ConversationContext';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { BACKEND_URL } from '../../constants'

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
});

export default function NotePad() {
  const { selectedSenderId, selectedBotChannel, note, setNote, tagName }: any = useContext(ConversationContext)
  const [label, setLabel] = useState("Write a note");
  const [tagString, setTagString] = useState("");

  useEffect(() => {
    if (!tagName) return;

    if (tagName && tagName.length > 1) {
      setTagString(tagName.join(","))
    } else {
      setTagString(tagName[0])
    }
  }, [tagName]);

  const handleNoteChange = async (event: any) => {
    const noteValue = event.target.value;
    setNote(noteValue);
    setLabel('Saving...')
    await saveNoteToBackend(noteValue);
  };

  const saveNoteToBackend = async (noteValue: any) => {
    const payload = {
      sender_id: selectedSenderId,
      bot_channel: selectedBotChannel,
      note: noteValue,
      tags: tagString
    };
    try {
      const response = await fetch(`${BACKEND_URL}/conversation-remark/${selectedSenderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      if (data === null) { // create conversation remark
        const response = await fetch(`${BACKEND_URL}/conversation-remark`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
      }
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLabel('Saved!')
    } catch (error) {
      console.error("Failed to save note:", error);
      setLabel('Error!')
    }
  };

  useEffect(() => {
    setLabel("Write a note")
    const fetchNoteFromBackend = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/conversation-remark/${selectedSenderId}`);
        const data = await response.json();
        setNote(data.note);
      } catch (error) {
        console.error("Failed to fetch note from backend:", error);
      }
    };
    fetchNoteFromBackend();
  }, [selectedSenderId]);

  return (
    <ThemeProvider theme={theme}>
      <TextField
        id="standard-multiline-flexible"
        label={label}
        multiline
        rows={5}
        variant="outlined"
        value={note}
        onChange={handleNoteChange}
        style={{ width: '90%' }}
      />
    </ThemeProvider>
  );
}







