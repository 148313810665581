import { useContext } from 'react'
import { Input, Avatar, Paper, Box, Typography, Divider } from '@mui/material';

import List from '@mui/material/List';

import ConversationInput from './conversation-input'
import ConversationHeader from './conversation-header';
import ConversationMessage from './conversation-message'
import HistoryContext from '../context/HistoryContext';

import ReactLoading from "react-loading";

const ConversationContainer = () => {
    const { selectedSenderId, network }: any = useContext(HistoryContext)

    return (
        <div style={{ height: '95%', width: '65%', display: 'inline-block', verticalAlign: 'middle' }}>
            {
                network == false &&
                <Paper style={{ height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'auto', backgroundColor: 'rgba(52, 52, 52, 0.1)' }}>
                    <ReactLoading
                        type="spinningBubbles"
                        color="rgba(2, 189, 101, 1)"
                        height={100}
                        width={50}
                    />
                    <br/>
                    <Typography>Reconnecting to the server... Please wait</Typography>
                </Paper>            
            }
            { network == true && selectedSenderId == '' &&
                <Paper style={{ height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'auto', backgroundColor: 'white' }}>
                    <Typography variant='h5'>SELECT A CONVERSATION</Typography>
                </Paper>
            }
            { network == true && selectedSenderId !== '' &&
                <Paper style={{ height: '100%', width: '100%', display: 'inline-block', verticalAlign: 'middle' }}>
                    <ConversationHeader />
                    <Divider />
                    <ConversationMessage
                    />
                    {/* <Divider />
                    <ConversationInput/> */}
                </Paper>
            }
        </div>
    )
}

export default ConversationContainer