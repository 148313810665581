import {
    useEditController,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    TextField,
    SelectField,
    usePermissions,
    Tab,
    TabbedShowLayout,
    DateField
} from 'react-admin';
import Button from '@mui/material/Button';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, createTheme } from '@mui/material/styles';
import { CustomDateField, CustomBotChannelField, AppointmentActionField, ApiStatusField } from '../custom-components'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const theme = createTheme({
//     button: {
//         margin: theme.spacing(1)
//     }
//   });

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <Button variant="contained" onClick={() => navigate("/appointment")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}

const AppointmentShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.appointment.form.show')} 
            {...props}
            actions={<ListActions />} >
            <TabbedShowLayout>
                <Tab label={translate('resources.appointment.tab.schedule')}>
                    <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                        <CustomBotChannelField  source="bot_channel"
                            label={translate('resources.appointment.fields.bot_channel')}  
                        />
                        <TextInput  source="appointment_date"
                            disabled
                            fullWidth
                            label={translate('resources.appointment.fields.appointment_date')}  
                        />
                        <TextInput  source="time_from"
                            disabled
                            fullWidth                      
                            label={translate('resources.appointment.fields.time_from')}  
                        />
                        <TextInput  source="time_to"
                            disabled
                            fullWidth
                            label={translate('resources.appointment.fields.time_to')}  
                        />
                        <TextInput  source="appointment_action"
                            disabled
                            label={translate('resources.appointment.fields.appointment_action')}  
                            fullWidth/>
                        <TextInput  source="remarks"
                            disabled
                            multiline  
                            label={translate('resources.appointment.fields.remarks')}  
                            fullWidth/>
                        <TextInput  source="appointment_id"
                            disabled
                            label={translate('resources.appointment.fields.appointment_id')}  
                            fullWidth/>
                        <ApiStatusField  source="api_status"
                            label={translate('resources.appointment.fields.api_status')}  
                        />
                        <TextInput  source="api_error"
                            label={translate('resources.appointment.fields.api_error')} 
                            disabled 
                            fullWidth/>
                    </SimpleForm>
                </Tab>
                <Tab label={translate('resources.appointment.tab.customer')}>
                    <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                        <TextInput  source="fullname"
                            disabled
                            label={translate('resources.appointment.fields.fullname')} 
                            fullWidth/>
                        <TextInput  source="gender"
                            disabled
                            label={translate('resources.appointment.fields.gender')}  
                            fullWidth/>
                        <TextInput  source="date_of_birth"
                            disabled
                            fullWidth
                            label={translate('resources.appointment.fields.date_of_birth')}  
                        />
                        <TextInput  source="marital"
                            disabled
                            label={translate('resources.appointment.fields.marital')}  
                            fullWidth/>
                        <TextInput  source="nationality"
                            disabled
                            label={translate('resources.appointment.fields.nationality')}  
                            fullWidth/>
                        <TextInput  source="nric"
                            disabled
                            label={translate('resources.appointment.fields.nric')}  
                            fullWidth/>
                        <TextInput  source="passport"
                            disabled
                            label={translate('resources.appointment.fields.passport')}  
                            fullWidth/>
                        <TextInput  source="email"
                            disabled
                            type="email"
                            label={translate('resources.appointment.fields.email')} 
                            fullWidth/>
                        <TextInput  source="contact"
                            disabled
                            label={translate('resources.appointment.fields.contact')}  
                            fullWidth/>
                        <TextInput  source="address"
                            disabled
                            label={translate('resources.appointment.fields.address')}  
                            fullWidth/>
                    </SimpleForm>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
}

export default AppointmentShow;