import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useRef,
    useLayoutEffect
} from 'react'
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles,
    Box, 
    IconButton,
    InputAdornment
} from '@mui/material';

import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import SearchIcon from '@mui/icons-material/Search';
import { ActionReducer } from '../reducers/reducers';
import ConversationContext from '../context/ConversationContext';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReactLoading from "react-loading";
import BlockIcon from '@mui/icons-material/Block';
import { Button } from 'react-admin';

const ConversationMessage = () => {
    const { messages, selectedAgentId, selectedSenderId, wsRef, targetId, inputDisabled, receiveHandover, agentIsHandler }: any = useContext(ConversationContext)
    const [shouldScroll, setShouldScroll] = useState(true);
    const messagesEndRef = useRef<HTMLDivElement>(null);
  
    function scrollToBottom() {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    useLayoutEffect(() => {
      if (shouldScroll) {
        scrollToBottom();
      }
    }, [messages, shouldScroll]);

    useLayoutEffect(() => {
      if (messagesEndRef.current) {
        const observer = new MutationObserver(scrollToBottom);
        observer.observe(messagesEndRef.current, { childList: true });
        return () => observer.disconnect();
      }
    }, []);

    function handleScroll() {
        const element = messagesEndRef.current;
        if (element) {
          const { scrollTop, scrollHeight, clientHeight } = element;
          const isAtBottom = scrollTop + clientHeight >= scrollHeight;
          setShouldScroll(isAtBottom);
        }
    }

    // useEffect(() => {
    //     // scrollPageToBottom();
    //     setScrollToBottom(true)
    // }, [scrollToBottom]);

    // useEffect(() => {
    //     setScrollToBottom(false)
    // }, [selectedSenderId]);

    // useEffect(() => {
    //     setScrollToBottom(true)
    // }, [selectedSenderId]);

    // useEffect(() => {
    //     if (bottomDivRef.current) {
    //         bottomDivRef.current.scrollIntoView({ block: 'end' })
    //         setScrollToBottom(true)
    //     }
    // }, [scrollToBottom]);

    // Broadcast Attachment URL to Live Agent
    const handleAttachmentIconClick = (event: any, attachment: any) => {
        window.open(attachment);
    }

    const AgentAttachment = ({agent, message, time, attachment, status_code}: any) => {
        return (
        <Box display='flex' justifyContent='flex-end' p={1}>
            <Box p={1} boxShadow={2} bgcolor="#b9f6ca" color="000000" flexWrap="wrap" maxWidth='65vh'>
                <Typography variant="body2" align="left">
                    <b>{agent}:</b>
                </Typography>
                <IconButton onClick={(event) => handleAttachmentIconClick(event, attachment)}>
                    <AttachFileIcon fontSize='large'/>
                </IconButton>
                <Typography variant="caption" align="left">
                    <em>{message}</em>
                </Typography>
                <Typography variant="body2" align="right">
                    <em>{time}</em>
                </Typography>
            </Box>
        </Box>      
    )}

    const UserAttachment = ({user, message, time, attachment, status_code}: any) => {
        return (
        <Box display='flex' justifyContent='flex-start' p={1}>
            <Box p={1} boxShadow={2} bgcolor="#ffffff" color="000000" flexWrap="wrap"  maxWidth='65vh'>
                <Typography variant="body2" align="left">
                    <b>{user}:</b>
                </Typography>
                <IconButton onClick={(event): any => handleAttachmentIconClick(event, attachment)}>
                    <AttachFileIcon fontSize='large'/>
                </IconButton>
                <Typography variant="caption" align="left">
                    <em>{message}</em>
                </Typography>
                <Typography variant="body2" align="right">
                    <em>{time}</em>
                </Typography>
            </Box>
            {
                status_code == 2 && <Box color="#f5f5f5"><ErrorOutlineIcon/> Failed to deliver</Box>
            }   
        </Box>      
    )}

    const UserMessage = ({ user, message, time, status_code }: any) => {
        return (
            <Box display='flex' justifyContent='flex-start' p={1}>
                <Box p={1} alignItems="flex-start" boxShadow={2} bgcolor="#ffffff" color="#000000" whiteSpace='pre-wrap' maxWidth='65vh' flexWrap='wrap'>
                    <Typography variant="body2" align="left">
                        <b>{user}:</b>
                    </Typography>
                    <Typography variant="body1" align="left">
                        <em>{message}</em>
                    </Typography>
                    <Typography variant="body2" align="right">
                        <em>{time}</em>
                    </Typography>
                </Box>
                {
                    status_code == 2 && <Box color="#f5f5f5"><ErrorOutlineIcon/> Failed to deliver</Box>
                }   
            </Box>

        )
    }
    
    const BotMessage = ({ bot, message, time, status_code }: any) => (
        <Box display='flex' justifyContent='flex-end' p={1}>
            <Box p={1} alignItems="flex-start" boxShadow={2} bgcolor="#b9f6ca" color="#000000" whiteSpace='pre-wrap' maxWidth='65vh' flexWrap='wrap'>
                <Typography variant="body2" align="left">
                    <b>{bot}:</b>
                </Typography>
                <Typography variant="body1" align="left">
                    <em>{message}</em>
                </Typography>
                <Typography variant="body2" align="right">
                    <em>{time}</em>
                </Typography>
            </Box>
            {   
                status_code == 2 && <Box display="block" color="#757575" fontSize="small"><ErrorOutlineIcon/> Failed to deliver</Box>
            }   
        </Box>
    )

    return (
        <Fragment>
            <div onScroll={handleScroll} style={{ height: '85%', overflow: 'auto', backgroundColor: '#fafafa'}}>
                { (!selectedAgentId || (agentIsHandler === true || receiveHandover === true)) && 
                    messages && messages.length > 0 && messages.map((message: any) => {
                        if (message.attachment_link && message.action == "user") {
                            return <UserAttachment user="User" time={message.created_at} message={message.attachment_name} attachment={message.attachment_link} status_code={message.status}/>
                        } else if (message.attachment_link && message.action == "live_agent") {
                            return <AgentAttachment agent="Live Agent" time={message.created_at} message={message.attachment_name} attachment={message.attachment_link} status_code={message.status}/>
                        } else if (message.action == "user" || message.sender == "user") {
                            return <UserMessage user="User" message={message.message} time={message.created_at} status_code={message.status}/>
                        } else if (message.action == "bot") {
                            return <BotMessage bot="Bot" message={message.message} time={message.created_at} status_code={message.status}/>
                        } else if (message.action == "live_agent" || message.sender == "live_agent") {
                            return <BotMessage bot="Live Agent" message={message.message} time={message.created_at} status_code={message.status}/>
                        }
                    })
                }
                { selectedAgentId && agentIsHandler === false && receiveHandover === false && 
                    <Paper style={{ overflowX: 'hidden',height: '100%', width: '100%', display: 'inline-flex', flexDirection: 'column', verticalAlign: 'middle', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', backgroundColor: 'rgba(52, 52, 52, 0.1)', opacity: .5 }}>
                        <BlockIcon/>
                        <br/>
                        <Typography>Live Conversation Going On, Please wait for Permission</Typography>
                    </Paper>       
                }
                <div ref={messagesEndRef} />
            </div>       
        </Fragment>
    )
}

export default ConversationMessage
