import { useReducer } from "react";

const ActionReducer = (state: any, action: any) => {
    console.log(action)
    switch (action.action) {
        case "listen":
            return action
        case "register":
            return action.action
        default:
            return state;
    }
};


export { ActionReducer }