import React, {cloneElement, useMemo} from 'react'
import IconEvent from '@mui/icons-material/Event';
import { useTranslate, Filter, TextInput, useListContext, TopToolbar, CreateButton, ExportButton,Button,sanitizeListRestProps,List, Datagrid, TextField, Pagination, downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'

const PermissionFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q"/>
            <TextInput label={translate('resources.permission.fields.permission_code')} source="permission_code"/>
            <TextInput label={translate('resources.permission.fields.permission_name')} source="permission_name"/>
        </Filter>
    )
}
const exporter = (permissions: any) => {
    const permissionsForExport = permissions.map((permission: any) => {
        const { backlinks, author, ...permissionForExport } = permission
        permissionForExport.permission_code = permission.permission_code
        return permissionForExport
    })
    jsonExport(permissionsForExport, {
        headers: ['permission_code', 'permission_name','module'],
    }, (err, csv) => {
        downloadCSV(csv, 'permissions')
    })
}

const PermissionPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>


const PermissionList = (props: any) => (

/* <List {...props} title="List of permissions" exporter={false} actions={<ListActions />} pagination={<PermissionPaginator />}> */
/* <List {...props} exporter={false} pagination={<PermissionPaginator />} bulkActionButtons={false} > */

<List {...props} exporter={false} pagination={<PermissionPaginator />} filters={<PermissionFilter/>}>
        <Datagrid>
            <TextField source="permission_code"/>
            <TextField source="permission_name"/>
            <TextField source="module"/>
        </Datagrid>
    </List>
)


export default PermissionList