import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useReducer,
    useMemo,
    useRef,
    createContext
} from 'react'
import { 
    usePermissions,
} from 'react-admin';
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles
} from '@mui/material';
import {
    Title
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ConversationInput from './conversation-input'
import ConversationHeader from './conversation-header';
import ConversationMessage from './conversation-message'
import ConversationToolbar from './conversation-toolbar'
import ConversationList from './conversation-list'
import ConversationContainer from './conversation-container';
import { DataProvider } from '../context/ConversationContext'
import ConversationContext from '../context/ConversationContext';
import ConversationChannel from './conversation-channel';
import ConversationFilter from './conversation-filter'
import RequestDashboard from './components/request-dashboard'

import { APP_PERMISSIONS } from '../constants';
import { HistoryProvider } from '../context/HistoryContext'
import HistoryContext from '../context/HistoryContext';

const ConversationShell = () => {
    // const classes = useStyles()
    const { selectedSenderId, chats }: any = useContext(HistoryContext)
    const [selectedConversation, setSelectedConversation] = useState(false)
    const {permissions} = usePermissions();

    useEffect(() => {
        // Avoid useEffect to run twice on mount at the first time
        if (selectedSenderId){
            setSelectedConversation(true)
        }
    }, [selectedSenderId]);
    // // Calling the list of sender id from backend
    // useEffect(() => {
    //     // Avoid useEffect to run twice on mount at the first time
    //     if (listref.current === true){
    //         axios
    //         .get(`http://localhost:8000/conversations`)
    //         .then((res: any) => setConversationsList(res.data.result))
    //         .catch((err: any) => { console.warn(err) })
    //     }
    //     return () => {
    //         listref.current = true
    //     }
    // }, []);

    return (
        <div style={{ height: '95vh', width: '100%', overflow: 'auto' }}>
            {
                permissions ? permissions.includes(APP_PERMISSIONS.CONVERSATION.READ) && 
                <>
                <HistoryProvider>
                    <br></br>
                    <Paper style={{ height: '95%', maxWidth: 'fit-content', display: 'inline-block', verticalAlign: 'middle' }}>
                        <ConversationToolbar />
                        <ConversationFilter />
                        <Divider/>
                        <ConversationChannel />
                        <Divider />
                        <ConversationList
                        />
                    </Paper>
                    <ConversationContainer/>
                </HistoryProvider>
                </>
                : ""
            }
        </div>
    )
}

export default ConversationShell