import React, { useState } from 'react';
import { Button, TextField, Paper, Stack, Typography } from '@mui/material';
import { BACKEND_URL } from '../constants';
import {
  useNotify,
} from 'react-admin';

const UserAccount = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const identity = sessionStorage.getItem('identity')
  const access_token = sessionStorage.getItem('token')
  const notify = useNotify();

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(e.target.value === newPassword);
  };

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      if (newPassword === '' || !/[A-Z]/.test(newPassword) || !/[!@#$%^&*]/.test(newPassword)) {
        console.log('Invalid password. Please make sure it is not empty and contains at least one special character and one capital letter.');
      } else {
        fetch(`${BACKEND_URL}/user/update-user-password/${identity}?new_password=${newPassword}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          },
          body: JSON.stringify({
            new_password: newPassword
          }),
        })
          .then((response) => {
            if (response.ok) {
              notify('Password changed successfully');
              setNewPassword('');
              setConfirmPassword('');
              setPasswordsMatch(true);
            } else {
              notify('Failed to change password');
              // Handle error case, e.g., display an error message to the user
            }
          })
          .catch((error) => {
            notify('Error occurred while changing password', error);
            // Handle error case, e.g., display an error message to the user
          });
      }
    } else {
      console.log('Passwords do not match');
      setPasswordsMatch(false);
    }
  };
  

  return (
    <div style={{ margin: '5px 5px 5px 5px', width: '50%', height: '50%'}}>
      <Paper elevation={2} style={{ padding: '20px'}}>
        <Typography variant="h6" gutterBottom>
          Edit Account Password
        </Typography>
        <Stack m={2} style={{maxWidth:'400px'}} spacing={2} direction="column">
          <TextField
            variant="outlined"
            type="password"
            id="new-password"
            label="New Password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            error={!passwordsMatch || (newPassword !== '' && (!/[A-Z]/.test(newPassword) || !/[!@#$%^&*]/.test(newPassword)))}
            helperText={
              (!passwordsMatch && 'Passwords do not match') ||
              (newPassword !== '' &&
                (!/[A-Z]/.test(newPassword) || !/[!@#$%^&*]/.test(newPassword)) &&
                'Invalid password. Must contain at least one special character and one capital letter.')
            }
          />
          <TextField
            variant="outlined"
            type="password"
            id="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={!passwordsMatch}
            helperText={!passwordsMatch && 'Passwords do not match'}
          />
          <Button
            variant="contained"
            color="success"
            onClick={handleChangePassword}
            disabled={!passwordsMatch || newPassword === '' || (newPassword !== '' && (!/[A-Z]/.test(newPassword) || !/[!@#$%^&*]/.test(newPassword)))}
          >
            Change Password
          </Button>
        </Stack>
      </Paper>
    </div>
  );
};

export default UserAccount;
