import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, TextInput } from 'react-admin';

const CustomContent = ({ source, label, helperText }) => {
    const record = useRecordContext();
    
    console.log(record)

    let jsonRecord = JSON.parse(record[source]);

    console.log(jsonRecord)

    console.log(jsonRecord["text"])

    let recordText = jsonRecord["text"]
    let recordButtons = jsonRecord["buttons"]

    return (
        <>
        <TextInput helperText={helperText} fullWidth multiline disabled label={label} source={source} defaultValue={recordText}/>
        <TextInput helperText={helperText} fullWidth multiline disabled label={label} source={source} defaultValue={recordButtons}/>
        </>
    )
}


CustomContent.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
CustomContent.defaultProps = {
    addLabel: true
}
export default CustomContent;