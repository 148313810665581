import {
    useState,
    cloneElement,
    useEffect,
    Fragment,
    useContext,
    useReducer,
    useMemo,
    useRef,
    createContext
} from 'react'
import {
    usePermissions,
} from 'react-admin';
import {
    Input,
    Divider,
    Avatar,
    Paper,
    Typography,
    ToggleButton,
    makeStyles
} from '@mui/material';
import {
    Title
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

import ConversationInput from './conversation-input'
import ConversationHeader from './conversation-header';
import ConversationMessage from './conversation-message'
import ConversationToolbar from './conversation-toolbar'
import ConversationList from './conversation-list'
import ConversationSidebar from './conversation-sidebar';
import ConversationContainer from './conversation-container';
import { DataProvider } from '../context/ConversationContext'
import ConversationContext from '../context/ConversationContext';
import ConversationChannel from './conversation-channel';
import ConversationFilter from './conversation-filter'
import RequestDashboard from './components/request-dashboard'

import { APP_PERMISSIONS } from '../constants';

const ConversationShell = () => {
    // const classes = useStyles()
    const { selectedSenderId, chats }: any = useContext(ConversationContext)
    const [selectedConversation, setSelectedConversation] = useState(false)
    const { permissions } = usePermissions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [arrowBackButtonClicked, setArrowBackButtonClicked] = useState(false);
    const [headerBackButtonClicked, setHeaderBackButtonClicked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Avoid useEffect to run twice on mount at the first time
        if (selectedSenderId) {
            setSelectedConversation(true)
        }
    }, [selectedSenderId]);


    return (
        <div id='conversation-shell'>
            {permissions && permissions.includes(APP_PERMISSIONS.CONVERSATION.READ) && (
                <>
                    <br />
                    {!isMobile && (
                        <><Paper className='conversation-shell-paper'>
                            <ConversationToolbar />
                            <ConversationFilter />
                            <Divider />
                            <ConversationChannel />
                            <Divider />
                            <RequestDashboard />
                            <Divider />
                            <ConversationList />
                        </Paper>
                            <ConversationContainer setButtonClicked={setButtonClicked} setArrowBackButtonClicked={setArrowBackButtonClicked} setHeaderBackButtonClicked={setHeaderBackButtonClicked} />
                            <ConversationSidebar setArrowBackButtonClicked={setArrowBackButtonClicked} setButtonClicked={setButtonClicked} />
                        </>
                    )}
                    {isMobile && selectedSenderId === '' && (
                        <Paper className='conversation-shell-paper'>
                            <ConversationToolbar />
                            <ConversationFilter />
                            <Divider />
                            <ConversationChannel />
                            <Divider />
                            <RequestDashboard />
                            <Divider />
                            <ConversationList />
                        </Paper>
                    )}
                    <>
                        {isMobile && selectedSenderId !== '' && !buttonClicked && (
                            <ConversationContainer setButtonClicked={setButtonClicked} setArrowBackButtonClicked={setArrowBackButtonClicked} setHeaderBackButtonClicked={setHeaderBackButtonClicked} />
                        )}
                        {isMobile && selectedSenderId !== '' && buttonClicked && !arrowBackButtonClicked && (
                            <ConversationSidebar setArrowBackButtonClicked={setArrowBackButtonClicked} setButtonClicked={setButtonClicked} />
                        )}
                        {/* {isMobile && selectedSenderId !== '' && headerBackButtonClicked && !arrowBackButtonClicked && (
                            <ConversationContainer setButtonClicked={setButtonClicked} setArrowBackButtonClicked={setArrowBackButtonClicked} setHeaderBackButtonClicked={setHeaderBackButtonClicked} />
                        )} */}
                    </>
                </>
            )}
        </div>
    )
}

export default ConversationShell