import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PredictionShow from './prediction-show';
import PredictionEdit from './prediction-edit';
// import PredictionCreate from './prediction-create';
import PredictionList from './prediction-list';

export default {
    // create: PredictionCreate,
    list: PredictionList,
    show: PredictionShow,
    edit: PredictionEdit,
    icon: QuestionAnswerIcon,
}
