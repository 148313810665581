import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, TextInput } from 'react-admin';

const ApiStatusField = ({ source, label }) => {
    const record = useRecordContext();

    return (
        <TextInput disabled label={label} source={source} defaultValue={
            record[source] ? (record[source] == 1 ? 'Success' : 'Failed') : "Unknown" 
        }/>
    )
}

ApiStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
ApiStatusField.defaultProps = {
    addLabel: true
}
export default ApiStatusField;