import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import IntentShow from './intent-show';
import IntentEdit from './intent-edit';
import IntentCreate from './intent-create';
import IntentList from './intent-list';

export default {
    create: IntentCreate,
    list: IntentList,
    show: IntentShow,
    edit: IntentEdit,
    icon: QuestionAnswerIcon,
}
