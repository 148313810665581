import { 
    useTranslate, 
    TextInput, 
    ShowButton, 
    FilterButton,
    useListContext, 
    TopToolbar, 
    usePermissions,
    List, 
    Datagrid, 
    TextField, 
    RichTextField, 
    Pagination, 
    downloadCSV, 
    useRedirect, 
    FunctionField, 
    CreateButton,
    EditButton
} from 'react-admin'
import Button from '@mui/material/Button';
import {cloneElement} from 'react'
import { useNavigate } from 'react-router-dom' 
import { CustomDateField, CustomDateTimePicker } from '../custom-components'
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
// import IconButton from '@material-ui/core/IconButton'
// import { sendTemplateMessage } from '../redux/actions'
// import { template_message } from '../redux/store'
// import Icon from '@material-ui/core/Icon'

// Filters for the Promotion listing
const PromotionFilter = [
    <TextInput source="broadcast_name"/>,
    <TextInput source="template_name"/>
]

// Paginator for the Promotion listing
const PromotionPaginator = (props: any) => <Pagination rowsPerPageOptions={[15,30,60,90,120,150]} {...props}/>

const ListActions = ({...props}) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    
    const {
        resource,
        displayedFilters,
        filterValues,
        selectedIds,
        showFilter,
        total,
    } = useListContext()

    const navigate = useNavigate()
        return (
            <div>
            <TopToolbar>
            <FilterButton/>
            {      
            <Button variant="text" onClick={() => navigate('/promotions/create')} startIcon={<AddIcon />}>
                ADD PROMOTION
            </Button>
            }
            {filters && cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}   
            </TopToolbar>
            </div>
        )
}


const PromotionList = ({...props}) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    return (
        <List actions={<ListActions permissions={permissions}/>} filters={PromotionFilter} title={translate('resources.promotions.form.list')} bulkActionButtons={false} exporter={false} pagination={<PromotionPaginator/>}>
            <Datagrid>
                <TextField label={translate('resources.promotions.fields.broadcast_name')} source="broadcast_name"/>
                <TextField label={translate('resources.promotions.fields.template_name')} source="template_name"/>
                <ShowButton />
                {/* <EditButton /> */}
                
                {/* {permissions ? permissions.includes("MARKETING:WRITE") && <EditButton /> : ""} */}
            </Datagrid>
        </List>
    )
}

export default PromotionList