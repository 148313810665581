import {
    useEditController,
    Show,
    SimpleShowLayout,
    Toolbar,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    useTranslate,
    BooleanInput,
    SimpleForm,
    TextInput,
    TextField,
    SelectField,
    usePermissions,
    DateInput
} from 'react-admin';
import Button from '@mui/material/Button';
import { useState, cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1)
//     }
// }))

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({ ...props }) => {
    const navigate = useNavigate()
    return (
        <Toolbar {...props} >
            <EditButton record={props.data} />
            {/* <EditButton basePath = {props.basePath} record={props.data} /> */}
            <Button variant="contained" onClick={() => navigate("/live-agent-holiday")} startIcon={<ArrowBackIcon />}>
                BACK
            </Button>
        </Toolbar>
    )
}

const ListActions = (props: any) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
    return (
        <div>
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            </TopToolbar>
        </div>

    )
}

const HolidayShow = ({ ...props }) => {
    const translate = useTranslate()
    const { permissions } = usePermissions();
    return (
        <Show title={translate('resources.holiday.form.show')}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar permissions={permissions} />}>
                <br />
                <DateInput source="date_of_holiday"
                    disabled
                    label={translate('resources.holiday.fields.date_of_holiday')}
                    helperText={translate('resources.holiday.helperText.date_of_holiday')}
                    />
                <TextInput source="description"
                    disabled
                    label={translate('resources.holiday.fields.description')}
                    helperText={translate('resources.holiday.helperText.description')}
                    fullWidth />
            </SimpleForm>
        </Show>
    )
}

export default HolidayShow;