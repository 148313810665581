import React, { useEffect, useState, useCallback, ChangeEvent, useRef } from "react"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { Create, Toolbar, SaveButton, useTranslate, useNotify, useRefresh, useRedirect, RadioButtonGroupInput, FormDataConsumer, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength, TabbedForm, FormTab, FileInput, useInput, FileField, ArrayInput, SimpleFormIterator, DateTimeInput, SimpleList, TextInputProps } from 'react-admin';
import dataProvider from '../data-provider'
import moment, { lang } from 'moment';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, IconButton, TextField } from '@mui/material';

import RemoveIcon from '@mui/icons-material/Remove';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


import {
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
} from 'ra-input-rich-text';

const BottomToolbar = ({ saveButtonClicked, ...props }: any) => {
    const redirect = useRedirect();

    return (
        <Toolbar {...props} >
            <SaveButton />
            <Button variant="contained" onClick={() => redirect('/template')} startIcon={<CancelIcon />}>
                Cancel
            </Button>
        </Toolbar>
    )
}

const validateTitle = [required(), minLength(2), maxLength(255)];


const dateValidation = (value: any, allValues: any) => {
    if (!value) {
        return 'Date is required';
    }
    if (value <= new Date()) {
        return 'Must be after today ';
    }
    return [];
};


const validateDate = [required(), dateValidation];
const validateHeaderFooter = [required(), maxLength(60)];
const validateContent = [required()];



const BroadcastCreate = (props: any) => {
    const translate = useTranslate()
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const { source, ...rest } = props;

    const [placeholderAttachment, setPlaceholderAttachment] = React.useState("");
    const [message, setMessage] = useState('');
    const [text, setText] = useState('');

    const [button, setButton] = useState('');

    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [variables, setVariables] = useState(0)
    const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
    const [quickReplies, setQuickReplies] = useState(0);
    const [quickReply1, setQuickReply1] = useState('');
    const [quickReply2, setQuickReply2] = useState('');
    const [quickReply3, setQuickReply3] = useState('');

    const [buttonText, setButtonText] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [actionInputs, setActionInputs] = useState<{ buttonText: string; phoneNumber: string }[]>([]);

    const [variableAdded, setVariableAdded] = useState(false);
    const [boldAdded, setBoldAdded] = useState(true);
    const [italicAdded, setItalicAdded] = useState(true);
    const [strikeAdded, setStrikeAdded] = useState(true);
    const [selectedRange, setSelectedRange] = useState<{ start: number | null; end: number | null } | null>(null);

    const refContainer = useRef('')

    useEffect(() => {
        refContainer.current = message
    }, [message])

    // Get the element by aria-label
    const underlineButton = document.querySelector('[aria-label="Underline"]');
    const codeButton = document.querySelector('[aria-label="Code"]');

    // Check if the element exists
    if (underlineButton) {
        // Delete the element
        underlineButton.remove();
    } 

    // Check if the element exists
    if (codeButton) {
        // Delete the element
        codeButton.remove();
    } 

    // const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     setMessage(event.target.value);
    //     refContainer.current = event.target.value

    // };

    // const handleInputChange = (event: any) => {
    //     let newMessage = event
    //     console.log(variables)
        
    //     setMessage(newMessage);
    //     refContainer.current = newMessage

    //     if (variables > 0) {
    //         // Loop through variables starting from 1
    //         let v = 0
    //         for (let i = 1; i <= variables; i++) {
    //             // Construct the placeholder string
    //             const placeholder = `{{${i}}}`;
    //             // Check if the placeholder exists in the string
    //             if (newMessage.includes(placeholder)) {
    //                 console.log(`Found "${placeholder}" in the string.`);
    //                 v = v + 1
    //             } else {
    //                 console.log(`"${placeholder}" not found in the string.`);
    //             }
    //         }
    //         setVariables(v)
    //     }
    // };

    const handleInputChange = (event: any) => {
        let newMessage = event;
        console.log(variables);
        
        setMessage(newMessage);
        refContainer.current = newMessage;
    
        // if (variables > 0) {
        //     // Loop through variables starting from 1
        //     let updatedMessage = newMessage;
        //     let removedPlaceholders = 0;
        //     for (let i = 1; i <= variables; i++) {
        //         // Construct the placeholder string
        //         const placeholder = `{{${i}}}`;
        //         // Check if the placeholder exists in the string
        //         if (!newMessage.includes(placeholder)) {
        //             // Placeholder not found, remove it from the message
        //             updatedMessage = updatedMessage.replace(new RegExp(placeholder, 'g'), '');
        //             removedPlaceholders++;
        //         }
        //     }
    
        //     // Update the message with adjusted placeholders
        //     for (let i = 1; i <= variables - removedPlaceholders; i++) {
        //         const placeholder = `{{${i}}}`;
        //         const newPlaceholder = `{{${i - removedPlaceholders}}}`;
        //         updatedMessage = updatedMessage.replace(new RegExp(placeholder, 'g'), newPlaceholder);
        //     }
    
        //     // Update the variables count
        //     setVariables(variables - removedPlaceholders);
    
        //     // Update the message state
        //     setMessage(updatedMessage);
        // }
    };

    
    const finalizedText = (event: any) => {
        console.log(refContainer.current)
        return refContainer.current

    };

    const handleVariableAdd = (event: any) => {
        let value = message;
        setMessage(value)

        if (variables > 0 && !variableAdded) {
            setVariableAdded(true)
            // return value + `{{${variables}}}`;
        }

        if (selectedRange) {
            const start = selectedRange.start || 0;
            const end = selectedRange.end || 0;

            const beforeSelection = value.substring(0, start);
            const selectedText = value.substring(start, end);
            const afterSelection = value.substring(end);

            if (!boldAdded) {
                const formattedText = isBold ? selectedText : `<b>${selectedText}</b>`;
                setBoldAdded(true)
                // return `${beforeSelection}${formattedText}${afterSelection}`;
            } else if (!italicAdded) {
                const formattedText = isBold ? selectedText : `<i>${selectedText}</i>`;
                setItalicAdded(true)
                // return `${beforeSelection}${formattedText}${afterSelection}`;
            } else if (!strikeAdded) {
                const formattedText = isBold ? selectedText : `<s>${selectedText}</s>`;
                setStrikeAdded(true)
                // return `${beforeSelection}${formattedText}${afterSelection}`;
            }
        }
        return refContainer.current

    };

    const handleTemplateNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLastValues({
            ...lastValues, template_name: event.target.value, saveButtonClicked: true
        })
    };

    const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLastValues({
            ...lastValues, category: event.target.value, saveButtonClicked: true
        })
    };

    const handleLanguageChange = (event: any) => {
        setLastValues({
            ...lastValues, language: event.target.value, saveButtonClicked: true
        })
    };


    const handleSelect = (event: ChangeEvent<HTMLTextAreaElement | HTMLDivElement>) => {
        if ('selectionStart' in event.target && 'selectionEnd' in event.target) {
            setSelectedRange({
                start: event.target.selectionStart,
                end: event.target.selectionEnd,
            });
        }
    };

    const handleBoldClick = (variable: any) => {
        if (selectedRange) {
            if (!message) return;

            const start = selectedRange.start || 0;
            const end = selectedRange.end || 0;

            const beforeSelection = message.substring(0, start);
            const selectedText = message.substring(start, end);
            const afterSelection = message.substring(end);

            const formattedText = isBold ? selectedText : `<b>${selectedText}</b>`;

            setMessage(`${beforeSelection}${formattedText}${afterSelection}`);
            refContainer.current = `${beforeSelection}${formattedText}${afterSelection}`

            setBoldAdded(false)

        }
    };

    const handleItalicClick = () => {
        if (selectedRange) {
            if (!message) return;
            const start = selectedRange.start || 0;
            const end = selectedRange.end || 0;

            const beforeSelection = message.substring(0, start);
            const selectedText = message.substring(start, end);
            const afterSelection = message.substring(end);

            const formattedText = isBold ? selectedText : `<i>${selectedText}</i>`;

            setMessage(`${beforeSelection}${formattedText}${afterSelection}`);
            refContainer.current = `${beforeSelection}${formattedText}${afterSelection}`

            setItalicAdded(false)

        }
    };

    const handleStrikeClick = () => {
        if (selectedRange) {
            if (!message) return;

            const start = selectedRange.start || 0;
            const end = selectedRange.end || 0;

            const beforeSelection = message.substring(0, start);
            const selectedText = message.substring(start, end);
            const afterSelection = message.substring(end);

            const formattedText = isBold ? selectedText : `<s>${selectedText}</s>`;

            setMessage(`${beforeSelection}${formattedText}${afterSelection}`);
            refContainer.current = `${beforeSelection}${formattedText}${afterSelection}`

            setStrikeAdded(false)

        }
    };

    const handleAddVariableClick = () => {
        setVariables(variables + 1)
        let newMessage = message.replace('<p>', '').replace('</p>', '') + `{{${variables + 1}}}`
        setMessage(newMessage)
        refContainer.current = newMessage //message + `{{${variables + 1}}}`
        setVariableAdded(false)
    };

    const mediaChoices = [
        { id: 'IMAGE', name: 'Image' },
        { id: 'VIDEO', name: 'Video' },
        { id: 'DOCUMENT', name: 'Document' },
    ];

    const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedMedia(event.target.value);
        setLastValues({
            ...lastValues, media: event.target.value, saveButtonClicked: true
        })
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
    };

    const handleExampleChange = (index: any, event: React.ChangeEvent<HTMLInputElement>) => {
        if (index == 0) {
            setLastValues({
                ...lastValues, example_1: event.target.value, saveButtonClicked: true
            })
        } else if (index == 1) {
            setLastValues({
                ...lastValues, example_2: event.target.value, saveButtonClicked: true
            })
        } else if (index == 2) {
            setLastValues({
                ...lastValues, example_3: event.target.value, saveButtonClicked: true
            })
        }
    };

    const handleButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setButton(event.target.value);
        setLastValues({
            ...lastValues, interactive_message: event.target.value, saveButtonClicked: true
        })
    };

    const addQuickReply = () => {
        if (quickReplies < 3) {
            setQuickReplies(quickReplies + 1);
        }
    };

    const updateQuickReply = (index: number, text: string) => {
        if (index == 0) {
            setQuickReply1(text)
        } else if (index == 1) {
            setQuickReply2(text)
        } else if (index == 2) {
            setQuickReply3(text)
        }
    };

    const handleRemoveQuickReply = (index: number) => {
        setQuickReplies(quickReplies - 1);
        if (index == 0) {
            setQuickReply1('')
        } else if (index == 1) {
            setQuickReply2('')
        } else if (index == 2) {
            setQuickReply3('')
        }
    };

    const handleRemoveActionInputs = () => {
        setButtonText('');
        setPhoneNumber('');
    };

    const handleAddActionInputs = () => {
        if (actionInputs.length < 2) {
            setActionInputs((prev) => [...prev, { buttonText: '', phoneNumber: '' }]);
        }
    };

    const handleRemoveActionInput = (index: number) => {
        const newActionInputs = [...actionInputs];
        newActionInputs.splice(index, 1);
        setActionInputs(newActionInputs);
    };


    const [lastValues, setLastValues] = React.useState({
        template_name: '',
        category: '',
        language: '',
        media: '',
        interactive_message: '',
        example_1: '',
        example_2: '',
        example_3: '',
        quick_reply_text_1: '',
        quick_reply_text_2: '',
        quick_reply_text_3: '',
        message: message,
        variables: variables,
        quickReplies: quickReplies,
        saveButtonClicked: true,
    })

    const saveButtonHandler = (e: any) => {
        setLastValues({
            ...lastValues,
            message: message,
            variables: variables,
            quickReplies: quickReplies,
            saveButtonClicked: true
        })
    }

    const onSuccess = (data: any) => {
        notify(`Promotion "${data.broadcast_name}" successfully created`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    const onFailure = (error: any) => {
        notify(`Failed to create Promotion: ${error.message}`)
    };

    React.useEffect(() => {
        if (lastValues.saveButtonClicked) {
            setLastValues({
                ...lastValues, saveButtonClicked: false
            })
        }
    }, [lastValues])

    const handleRemoveClick = (index: any) => {
        if (index >= 0 && index < variables) {
            let updatedMessage = message;

            for (let i = index + 1; i <= variables; i++) {
                const replacement = i === variables ? '' : `{{${i}}}`;
                updatedMessage = updatedMessage.replace(`{{${i}}}`, replacement);
            }
            // // Replace all occurrences of {{n}} where n is greater than the removed index
            // for (let i = index + 1; i <= variables; i++) {
            //     const replacement = i - 1 === 0 ? '' : `{{${i - 1}}}`;
            //     updatedMessage = updatedMessage.replace(`{{${i}}}`, replacement);
            // }

            setMessage(updatedMessage);
            refContainer.current = updatedMessage

            setVariables(variables - 1)
        }
    };



    // /**
    //  * Method to remove id attribute before submitting for update to avoid server schema validation error
    //  * @param {*} data 
    //  */
    // const transformRecord = (data: any) => {
    //     for (var i = 0; i < data.broadcast_date.length; i++) {
    //         data.broadcast_date[i].converted_date = moment(data.broadcast_date[i].date).format("YYYY-MM-DD HH:mm")
    //     }
    //     return {
    //         ...data
    //     }
    // }

    return (

        <Create title={translate('resources.template.form.create')} onSuccess={onSuccess} onFailure={onFailure}  {...props} >
            <TabbedForm toolbar={<BottomToolbar />}
                defaultValues={{ message: lastValues.message, variables: lastValues.variables, quickReplies: lastValues.quickReplies }}
            // defaultValues={{ 
            //     template_name: lastValues.template_name,
            //     category: lastValues.category, 
            //     language: lastValues.language, 
            //     media: lastValues.media,
            //     example_1: lastValues.example_1, 
            //     example_2: lastValues.example_2, 
            //     example_3: lastValues.example_3, 
            //     quick_reply_text_1: lastValues.quick_reply_text_1,
            //     quick_reply_text_2: lastValues.quick_reply_text_2,
            //     quick_reply_text_3: lastValues.quick_reply_text_3,
            //     interactive_message: lastValues.interactive_message,
            //     message: lastValues.message, 
            //     variables: lastValues.variables, 
            //     quickReplies: lastValues.quickReplies 
            // }}
            >
                <FormTab label="General">

                    <TextInput variant="outlined" style={{ width: '50%' }} resettable={true} validate={[required()]}
                        label={translate('resources.template.fields.template_name')}
                        source="template_name"
                        onChange={handleTemplateNameChange}
                    />

                    <div>
                        <RadioButtonGroupInput
                            validate={[required()]}
                            source="category"
                            onChange={handleCategoryChange}
                            choices={[
                                { id: 'MARKETING', name: 'Marketing' },
                                { id: 'UTILITY', name: 'Utility' },
                                { id: 'AUTHENTICATION', name: 'Authentication' },
                            ]}
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                            row={false}
                        />
                    </div>

                    <SelectInput variant="outlined" style={{ width: '50%' }} resettable={true}
                        validate={[required()]}
                        label={translate('resources.template.fields.language')} optionValue="id"
                        source="language"
                        onChange={handleLanguageChange}
                        choices={[
                            { id: 'en', name: 'English' },
                            { id: 'tl', name: 'Tagalog' },
                            { id: 'in', name: 'Indonesian' },
                            { id: 'ms', name: 'Malay' },
                        ]}
                    />
                    <br />

                </FormTab>
                <FormTab label="Content">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ width: '48%' }}>
                            <h4>Header</h4>

                            <SelectInput
                                variant="outlined"
                                source="media"
                                label="Select Media"
                                choices={mediaChoices}
                                optionValue="id"
                                onChange={handleMediaChange}
                                style={{ width: '100%' }}
                                {...props}
                            />
                            {selectedMedia === 'DOCUMENT' && (
                                <FileInput multiple={false} label="Upload Document" variant="outlined" source="file_en" accept="application/pdf" placeholder={placeholderAttachment}>
                                    <FileField source="src" title="title" target="_blank" />
                                </FileInput>
                            )}
                            {selectedMedia === 'IMAGE' && (
                                <FileInput multiple={false} label="Upload Image" variant="outlined" source="file_en" accept="image/*" placeholder={placeholderAttachment} onChange={(e) => handleFileChange(e)} >
                                    <FileField source="src" title="title" target="_blank" />
                                </FileInput>
                            )}
                            {selectedMedia === 'VIDEO' && (
                                <FileInput multiple={false} label="Upload Video" variant="outlined" source="file_en" accept="video/*" placeholder={placeholderAttachment} >
                                    <FileField source="src" title="title" target="_blank" />
                                </FileInput>
                            )}

                            <h4>Body</h4>

                            <RichTextInput
                                source="message"
                                label=" "
                                parse={(value) => (value ? value : "")}
                                format={(e) => handleVariableAdd(e)}
                                onChange={(e) => handleInputChange(e)}
                                fullWidth
                                toolbar={
                                    <RichTextInputToolbar>
                                        <FormatButtons size="large" />
                                        <Button onClick={handleAddVariableClick} startIcon={<AddIcon/>}>Add Variables</Button>
                                        {/* <AddIcon onClick={handleAddVariableClick} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                        <a onClick={handleAddVariableClick} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <span style={{ verticalAlign: 'middle', cursor: 'pointer' }}></span>
                                        </a> */}
                                    </RichTextInputToolbar>
                                } />

                            {/* <TextInput
                                label="Please write the message here..."
                                multiline
                                rows={5}
                                type="text"
                                source="message"
                                format={(e) => handleVariableAdd(e)}
                                parse={(value) => (value ? value : "")}
                                // format={(value) => handleVariableAdd() && applyValue(message)}
                                value={message}
                                onChange={handleInputChange}
                                onSelect={(event: any) => handleSelect(event as ChangeEvent<HTMLTextAreaElement | HTMLDivElement>)}
                                style={{ width: '100%', border: '1px solid #ccc', padding: '8px' }}
                            />

                            <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                <FormatBoldIcon onClick={handleBoldClick} style={{ cursor: 'pointer' }} />
                                <FormatItalicIcon onClick={handleItalicClick} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                <FormatStrikethroughIcon onClick={handleStrikeClick} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                <AddIcon onClick={handleAddVariableClick} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                <a onClick={handleAddVariableClick} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <span style={{ verticalAlign: 'middle', cursor: 'pointer' }}>Add Variables</span>
                                </a>
                            </div> */}
                            <br />
                            <Stack spacing={1} display="flex" flexDirection="column">
                                {variables > 0 &&
                                    Array.from({ length: variables }).map((_, index) => (
                                        <Stack spacing={1} display="flex" flexDirection="row" alignItems="flex-start">
                                            <TextInput
                                                key={index}
                                                source={`example_${index + 1}`}
                                                onChange={(e) => handleExampleChange(index, e)}
                                                label={`Enter example for {{${index + 1}}}`}
                                            />
                                            <IconButton onClick={() => handleRemoveClick(index)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Stack>
                                    ))}
                            </Stack>
                            <br />
                            <h4>Interactive Message</h4>
                            <SelectInput
                                variant="outlined"
                                source="interactive_message"
                                label="Select Button"
                                choices={[
                                    { id: 'QUICK_REPLY', name: 'Quick Reply' },
                                    // { id: 'CALL_TO_ACTION', name: 'Call to Action' },
                                ]}
                                style={{ width: '100%' }}
                                onChange={handleButtonChange}
                                {...props}
                            />

                            {button === 'QUICK_REPLY' && (
                                <>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Stack spacing={1} display="flex" flexDirection="column">
                                            {quickReplies > 0 &&
                                                Array.from({ length: quickReplies }).map((_, index) => (
                                                    <Stack spacing={1} display="flex" flexDirection="row" alignItems="flex-start">
                                                        <TextInput
                                                            key={index}
                                                            source={`quick_reply_text_${index + 1}`}
                                                            onChange={(event) => updateQuickReply(index, event.target.value)}
                                                            label={`Button Text for {{${index + 1}}}`}
                                                        />
                                                        <IconButton onClick={() => handleRemoveQuickReply(index)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Stack>
                                                ))}
                                        </Stack>
                                        {quickReplies < 3 && (
                                            <Button onClick={addQuickReply}>
                                                Add Quick Reply
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                            {button === 'CALL_TO_ACTION' && (
                                <>
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                        {actionInputs.map((input, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Stack spacing={1} display="flex" flexDirection="row" alignItems="flex-start">
                                                    <TextInput
                                                        variant="outlined"
                                                        source={`button_text_${index}`}
                                                        label={`Button Text ${index + 1}`}
                                                        style={{ flex: 1, marginRight: 10 }}
                                                        multiline
                                                        rows={1}
                                                        value={input.buttonText}
                                                        onChange={(event) => {
                                                            const newActionInputs = [...actionInputs];
                                                            newActionInputs[index].buttonText = event.target.value;
                                                            setActionInputs(newActionInputs);
                                                        }}
                                                        {...props}
                                                    />
                                                    <TextInput
                                                        variant="outlined"
                                                        source={`phone_number_${index}`}
                                                        label={`Phone Number ${index + 1}`}
                                                        style={{ flex: 2 }}
                                                        multiline
                                                        rows={1}
                                                        value={input.phoneNumber}
                                                        onChange={(event) => {
                                                            const newActionInputs = [...actionInputs];
                                                            newActionInputs[index].phoneNumber = event.target.value;
                                                            setActionInputs(newActionInputs);
                                                        }}
                                                        {...props}
                                                    />
                                                    <IconButton onClick={() => handleRemoveActionInput(index)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Stack>
                                            </div>
                                        ))}
                                        {actionInputs.length < 2 && (
                                            <Button onClick={handleAddActionInputs}>
                                                Add Call to Action
                                            </Button>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        <div style={{ width: '48%' }}>
                            <h4>Message Preview:</h4>
                            <div style={{
                                width: '100%',
                                border: '1px solid #ccc',
                                padding: '8px',
                                height: '250px',
                                overflowY: 'auto',
                                backgroundImage: 'url("https://w0.peakpx.com/wallpaper/818/148/HD-wallpaper-whatsapp-background-cool-dark-green-new-theme-whatsapp.jpg")',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                position: 'relative',
                                color: 'white',
                            }}>
                                {message && (
                                    <div style={{
                                        background: 'white',
                                        color: 'black',
                                        padding: '10px',
                                        borderRadius: quickReply1 !== '' ? '10px 10px 0 0' : '10px',
                                        borderBottom: quickReply1 !== '' ? '1px solid rgb(189, 189, 189)' : '0',
                                        maxWidth: '80%',
                                        position: 'relative',
                                        margin: '0 10px 0 10px',
                                    }}
                                        dangerouslySetInnerHTML={{ __html: message }}
                                    />
                                )}
                                {quickReply1 !== '' && (
                                    <div style={{
                                        background: 'white',
                                        color: '#009dff',
                                        padding: '10px',
                                        borderRadius: quickReply2 !== '' ? '0' : '0 0 10px 10px',
                                        maxWidth: '80%',
                                        position: 'relative',
                                        textAlign: 'center',
                                        margin: '0 10px 0 10px',

                                    }}
                                        dangerouslySetInnerHTML={{ __html: quickReply1 }}
                                    />
                                )}
                                {quickReply2 !== '' && (
                                    <div style={{
                                        background: 'white',
                                        color: '#009dff',
                                        padding: '10px',
                                        borderRadius: quickReply3 !== '' ? '0' : '0 0 10px 10px',
                                        maxWidth: '80%',
                                        position: 'relative',
                                        textAlign: 'center',
                                        margin: '0 10px 0 10px',
                                    }}
                                        dangerouslySetInnerHTML={{ __html: quickReply2 }}
                                    />
                                )}
                                {quickReply3 !== '' && (
                                    <div style={{
                                        background: 'white',
                                        color: '#009dff',
                                        padding: '10px',
                                        borderRadius: '0 0 10px 10px',
                                        maxWidth: '80%',
                                        position: 'relative',
                                        textAlign: 'center',
                                        margin: '0 10px 0 10px',
                                    }}
                                        dangerouslySetInnerHTML={{ __html: quickReply3 }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </FormTab>

            </TabbedForm>
        </Create>
    )
}

export default BroadcastCreate