const BACKEND_URL = process.env.REACT_APP_FASTAPI_URL;
const WEBSOCKET_URL =   process.env.REACT_APP_WEBSOCKET_URL
const ROWS_PER_PAGE = 25;
const APP_PERMISSIONS = {
    "USER": {
        "CREATE": "USER:CREATE",
        "READ": "USER:READ",
        "WRITE": "USER:WRITE",
        "DELETE": "USER:DELETE",
    },
    "ROLE": {
        "CREATE": "ROLE:CREATE",
        "READ": "ROLE:READ",
        "WRITE": "ROLE:WRITE",
        "DELETE": "ROLE:DELETE",
    },
    "MARKETING": {
        "CREATE": "MARKETING:CREATE",
        "READ": "MARKETING:READ",
        "WRITE": "MARKETING:WRITE",
        "DELETE": "MARKETING:DELETE",
    },
    "CUSTOMER": {
        "CREATE": "CUSTOMER:CREATE",
        "READ": "CUSTOMER:READ",
        "WRITE": "CUSTOMER:WRITE",
        "DELETE": "CUSTOMER:DELETE",
    },
    "INTENT": {
        "CREATE": "INTENT:CREATE",
        "READ": "INTENT:READ",
        "WRITE": "INTENT:WRITE",
        "DELETE": "INTENT:DELETE",
    },
    "RESPONSE": {
        "CREATE": "RESPONSE:CREATE",
        "READ": "RESPONSE:READ",
        "WRITE": "RESPONSE:WRITE",
        "DELETE": "RESPONSE:DELETE",
    },
    "CONVERSATION": {
        "CREATE": "CONVERSATION:CREATE",
        "READ": "CONVERSATION:READ",
        "WRITE": "CONVERSATION:WRITE",
        "DELETE": "CONVERSATION:DELETE",
    },
    "APPOINTMENT": {
        "CREATE": "APPOINTMENT:CREATE",
        "READ": "APPOINTMENT:READ",
        "WRITE": "APPOINTMENT:WRITE",
        "DELETE": "APPOINTMENT:DELETE",
    },
    "MODEL": {
        "CREATE": "MODEL:CREATE",
        "READ": "MODEL:READ",
        "WRITE": "MODEL:WRITE",
        "DELETE": "MODEL:DELETE",
    }
}

export {
    BACKEND_URL, 
    WEBSOCKET_URL,
    APP_PERMISSIONS, 
    ROWS_PER_PAGE
}