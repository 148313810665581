import * as React from "react";
import { useShowContext, Show, EditButton, SingleFieldList,Toolbar, SimpleForm, Datagrid, TextInput, ArrayInput, SimpleFormIterator,useTranslate, TopToolbar,sanitizeListRestProps, BooleanInput,SelectArrayInput, FileField, SelectInput, DateField, ArrayField, TabbedShowLayout, Tab, FormDataConsumer, useRedirect,RadioButtonGroupInput } from 'react-admin';
import { Button, Table,TableBody,TableHead,TableCell,TableRow,Paper, TableContainer, Grid, InputLabel, OutlinedInput} from '@mui/material';
import dataProvider from '../data-provider'
import { useNavigate, useParams } from "react-router-dom"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { APP_PERMISSIONS } from '../constants';


const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const history = useNavigate()
        return (
            <div>
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                </TopToolbar>
            </div>
            
        )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({permissions,...props}:any) => {
  const redirect = useRedirect()

  return (
      <Toolbar {...props} >
        {/* {permissions ? permissions.includes(APP_PERMISSIONS.MARKETING.WRITE) && 
        <EditButton record={props.data} /> : ""} */}
          <Button variant="contained" onClick={() => redirect('/template')} color="inherit"  startIcon={<ArrowBackIcon />}>
              BACK
          </Button>
      </Toolbar>
  )
}

const BroadcastShow = (props: any) => {
    const translate = useTranslate()  
    const [showPastSchedule, setShowPastSchedule] = React.useState(false);
    const [customerList, setCustomerList]: any[] = React.useState([]);
    const [activeBroadcastDates, setActiveBroadcastDates]: any[] = React.useState([]);
    const [nonActiveBroadcastDates, setNonActiveBroadcastDates]: any[] = React.useState([]);
    const [hasContent, setHasContent] = React.useState(false);
    const [hasHeaderContent, setHasHeaderContent] = React.useState(false);
    // const [hasFooter, setHasFooter] = React.useState(false);
    const [hasButton, setHasButton] = React.useState(false);
    const [bodyContent, setBodyContent] = React.useState("");
    const [headerContent, setHeaderContent] = React.useState("");
    const [buttonContent, setButtonContent] = React.useState("");
    const params = useParams();

    // const [footerContent, setFooterContent] = React.useState("");
    
    // React.useEffect(() => {
    //   dataProvider.getOne('fetch-templates', { id: '125' })
    //       .then(({data}: any) => {
    //           console.log(data)
    //       })
    //       .catch(error => {
    //           // setError(error);
    //           // setLoading(false);
    //       })
    // }, []);

    // React.useEffect(() => {
    //     console.log(props)
    //     console.log('props.id', props.id)
    //     dataProvider.getOne('fetch-promotion', { id: params.id })
    //         .then(({ data }) => {
    //             console.log(data)
    //             setShowPastSchedule(data.show_past_dates)
    //             setCustomerList(data.customer_list)
    //             setActiveBroadcastDates(data.active_marketing_promotion_schedules)
    //             setNonActiveBroadcastDates(data.non_active_marketing_promotion_schedules)
    //             if (data.content != null){
    //                 setHasContent(true)
    //                 setBodyContent(data.content)
    //             }
    //             if (data.header_content != null){
    //                 setHasHeaderContent(true)
    //                 setHeaderContent(data.header_content)
    //             }

    //             // if (data.footer_content != null){
    //             //   setHasFooter(true)
    //             //   setFooterContent(data.footer_content)
    //             // } 

    //             if (data.button_content != null){
    //               setHasButton(true)
    //               setButtonContent(data.button_content)
    //             } 
    //         })
    //         .catch(error => {
    //             // setLoading(false);
    //         })
    // }, []);

    const CustomizedTables = ({}) => {
        return (
          <TableContainer component={Paper}>
            <Table aria-label="customized table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="left">Phone Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerList.map((row: any, index: any) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {index +1 }
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                    </TableRow> 
                  ))} 
                </TableBody>
              </Table>
            </TableContainer>
        );
    }

    const CustomizedDatesDisplayer = ({}) => {
      return (
        <TableContainer component={Paper}>
          <Table aria-label="customized date display" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell align="left">Broadcast Date</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonActiveBroadcastDates.map((row: any, index: any) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {index +1 }
                    </TableCell>
                    <TableCell align="left">{row.broadcast_date}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                  </TableRow> 
                ))} 
                {activeBroadcastDates.map((row: any, index: any) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {index +1 }
                    </TableCell>
                    <TableCell align="left">{row.broadcast_date}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                  </TableRow> 
                ))} 
              </TableBody>
            </Table>
          </TableContainer>
      );
  }

    const ShowHeaderParameters = (record: any) => {
      if (record.header_type == 2 || record.header_type == 3 || record.header_type == 4 ) {

        return(
          <div>
              <div>
                <br/>
                <InputLabel>Header</InputLabel>
                <hr/> 
              </div>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                  <a href={''+record.attachment} title="Attachment" download>Attachment</a>
                </Grid>
            </Grid>
          </div>
        )

      }else if (record.header_type == 1 ) {
        if (record.header_content != null && record.header_content != "null" ) {
          var params = JSON.parse(record.header_content)
          for (var i = 0; i < params.length; i++) {
            params[i]['id'] = i
          }
          record.header_parameters = params

          return(
            <ArrayInput source="header_parameters">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                      {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest
                      }) => 
                        scopedFormData ? (
                          <div>
                            <br/>
                            <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''}  disabled fullWidth/>
                          </div>

                        )
                        :null
                      }
                </FormDataConsumer>
              </SimpleFormIterator>
          </ArrayInput>          )
        }
        else{
          return(
            <span></span>
          )
  
        }
      }
      else{
        return(
          <span></span>
        )

      }
    };

    const ShowBodyParameters = (record: any) => {
      var hasParams = false
      if (record.body_content != null && record.body_content != "null" ) {
        hasParams = true
        var params = JSON.parse(record.body_content)
        for (var i = 0; i < params.length; i++) {
          params[i]['id'] = i
        }

        record.content_parameters = params  
        return(
          <ArrayInput source="content_parameters">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                      {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest

                      }) => 
                      
                        scopedFormData ? (
                          <div>
                            <br/>
                            <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''} multiline disabled fullWidth/>
                          </div>

                        )
                        :null
                      }
                </FormDataConsumer>
              </SimpleFormIterator>
          </ArrayInput>
      )

      }else{
        return(
          <span></span>
        )

      }
    };

    // const ShowFooterParameters = ({ record }) => {
    //   var hasParams = false
    //   if (record.footer_content != null && record.footer_content != "null" ) {
    //     hasParams = true
    //     var fparams = JSON.parse(record.footer_content)
    //     for (var i = 0; i < fparams.length; i++) {
    //       fparams[i]['id'] = i
    //     }

    //     record.footer_parameters = fparams  
    //     return(
    //       <ArrayInput source="footer_parameters">
    //         <SimpleFormIterator disableAdd disableRemove>
    //           <FormDataConsumer>
    //                   {({
    //                       formData, // The whole form data
    //                       scopedFormData, // The data for this item of the ArrayInput
    //                       getSource, // A function to get the valid source inside an ArrayInput
    //                       ...rest

    //                   }) => 
                      
    //                     scopedFormData ? (
    //                       <div>
    //                         <br/>
    //                         <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource('value')}  disabled fullWidth/>
    //                       </div>

    //                     )
    //                     :null
    //                   }
    //             </FormDataConsumer>
    //           </SimpleFormIterator>
    //       </ArrayInput>
    //   )

    //   }else{
    //     return(
    //       <span></span>
    //     )

    //   }
    // };

    const ShowButtonParameters = (record: any) => {
      if (record.button_content != null && record.button_content != "null" ) {
        var bparams = JSON.parse(record.button_content)
        for (var i = 0; i < bparams.length; i++) {
          bparams[i]['id'] = i
        }

        record.button_parameters = bparams  
        return(
          <ArrayInput source="button_parameters">
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                      {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest

                      }) => 
                      
                        scopedFormData ? (
                          <div>
                            <br/>
                            <TextInput variant="outlined" key={scopedFormData.id} label={scopedFormData.name} source={getSource?.('value') || ''} disabled fullWidth/>
                          </div>

                        )
                        :null
                      }
                </FormDataConsumer>
              </SimpleFormIterator>
          </ArrayInput>
      )

      }else{
        return(
          <span></span>
        )

      }
    };


    const CampaignShowTitle = (record: any) => {
        return <span>{translate('resources.template.name')} [{record.template_name}]</span>
    };

    return(
        <Show {...props} title={<CampaignShowTitle />}
        actions={<ListActions />} resource="template">
          <TabbedShowLayout>
            <Tab label="General">
                <SimpleForm toolbar={<BottomToolbar permissions={sessionStorage.getItem('permissions')}/>}>
                    <TextInput variant="outlined"  source="template_name" disabled fullWidth/>
                    <TextInput variant="outlined"  source="category" disabled fullWidth/>
                    <TextInput variant="outlined"  source="language" disabled fullWidth/>
                </SimpleForm>
            </Tab>
            <Tab label="Content">
                <SimpleForm toolbar={<BottomToolbar />}>
                    <TextInput variant="outlined"  source="media" disabled fullWidth/>
                    <TextInput variant="outlined" multiline source="message" disabled fullWidth/>
                    <TextInput variant="outlined"  source="interactive_message" disabled fullWidth/>
                </SimpleForm>
            </Tab>
            <Tab label="Status">
                <SimpleForm toolbar={<BottomToolbar />}>
                    <TextInput variant="outlined" source="status" disabled fullWidth />
                    <TextInput variant="outlined" multiline source="error_message" disabled fullWidth/>
                </SimpleForm>
            </Tab>
          </TabbedShowLayout>
        </Show>
    )
}

export default BroadcastShow
