import {
    Button,
    BooleanInput,
    EditButton,
    SimpleForm,
    Show,
    TextInput,
    Toolbar,
    usePermissions,
    useShowContext,
    useTranslate,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatDateTime } from '../utils';

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = (props: any) => {
    const navigate = useNavigate();
    const { permissions } = usePermissions();
    return (
        <Toolbar {...props} >
            {/* {permissions ? permissions.includes("USER:WRITE") &&
                        <EditButton />
                    :   ""
             } */}
            <Button label="Back" sx={{ m: 1, padding: 1 }} onClick={(e) => { e.preventDefault(); navigate("/users"); }} variant="contained" startIcon={<ArrowBackIcon />} />
        </Toolbar>
    )
}

/**
 * Construct the layout of User Form
 * 
 * @returns ShowLayout
 */
const ShowLayout = () => {
    const { isLoading, record } = useShowContext()

    if (!isLoading) {
        record.password_expiry = (record.password_expiry) ? formatDateTime(record.password_expiry) : ""
        record.created_at = (record.created_at) ? formatDateTime(record.created_at) : ""
        record.updated_at = (record.updated_at) ? formatDateTime(record.updated_at) : ""
    }

    return (
        <SimpleForm
                toolbar={<BottomToolbar />}
                sx={{ maxWidth: 500 }}
            >
                <BooleanInput disabled source="enabled" fullWidth  />  
                <SectionTitle label="resources.user.field_groups.identity" />
                <TextInput disabled source="username" fullWidth />
                <TextInput disabled source="fullname" fullWidth />
                <TextInput disabled source="email" fullWidth />
                <Separator />
                <SectionTitle label="resources.user.field_groups.security_access" />
                <TextInput disabled fullWidth source="role.role_name"/>
                <TextInput disabled source="password_expiry" fullWidth />              
                <BooleanInput disabled source="system_user" fullWidth />
                <Separator />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput disabled source="created_by" fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput disabled source="created_at" fullWidth />
                    </Box>
                </Box>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput disabled source="updated_by" fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput disabled source="updated_at" fullWidth />
                    </Box>
                </Box>
            </SimpleForm>
    )
}

/**
 * User Show
 * 
 * @returns UserShow
 */
const UserShow = () => {
    const translate = useTranslate();
    
    return (
        <Show title={translate('resources.user.form.show')} resource="user">
            <ShowLayout/>
        </Show>
    )
};

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserShow;