import { 
    Button,
    CreateButton,
    ShowButton,
    Datagrid,
    FilterButton, 
    List, 
    Pagination,
    TextField, 
    TextInput,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
    useNotify,
} from 'react-admin';
import { useState, cloneElement } from 'react';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';

import axios from 'axios';

import { BACKEND_URL } from '../constants';

// Filters for the Intent listing
const IntentFilter = [
    <TextInput source="bot_language"/>,
    <TextInput source="content"/>,
    <TextInput source="response_code"/>,
    // <TextInput source="remark"/>
]

const downloadCSV = async () => {
    const apiUrl = `${BACKEND_URL}/bot-response/export`;
    fetch(apiUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'bot-reponses.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        })
}

// Paginator for the Intent listing
const IntentPaginator = (props: any) => <Pagination rowsPerPageOptions={[15, 30, 60, 90, 120, 150]} {...props}/>


const IntentList = ({...props}) => {
    const translate = useTranslate()
    const {permissions} = usePermissions();
    const listContext = useListContext();
    const {
        data,
        filterValues,
        setFilters,
        displayedFilters,
        isLoading,
    } = listContext;

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const notify = useNotify();

    const handlePopoverClose = () => {
        setIsPopoverOpen(false);
    };

    const selectFile = () => {
        setIsPopoverOpen(true);
    };

    const handleImport = (event: any) => {
        importCsv(event.target.files[0])
        setIsPopoverOpen(false);
    };

    const importCsv = (selectedFile: any) => {
        const formData = new FormData();
        const token = sessionStorage.getItem('token')
        formData.append('upload_file', selectedFile);

        if (token && selectedFile) {
            formData.append('file', selectedFile)
            axios
                .post(`${BACKEND_URL}/bot-response/import`,
                        formData,
                        {headers: { 
                            'Content-Type': 'multipart/form-data', 
                            'Authorization': `Bearer ${token}` 
                        }}
                )
                .then(res => notify('Responses Uploaded'))
                .catch(err => notify(`Responses Upload Failed: ${err}`));
        }
    }

    const ListActions = () => (
        <TopToolbar>
            <Button
                label="Import"
                startIcon={<AddIcon />}
                onClick={selectFile}
            />
            <Button 
                label="Export" 
                onClick={downloadCSV}
                startIcon={<DownloadIcon/>}
            />
            <FilterButton/>
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                >
                <Box
                    sx={{
                        width: '300px', // Adjust the width as needed
                        height: '100px', // Adjust the height as needed
                        padding: '16px',
                    }}
                >
                    <Stack direction="row" display="flex" justifyContent="space-between">
                        <Typography>
                            Select a file to import:
                        </Typography>
                        <Button
                            label="x"
                            onClick={handlePopoverClose}
                        />
                    </Stack>
                    <br/>
                    <br/>
                    <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        name="pic"
                        id="fileUpload"
                        type="file"
                        multiple={false}
                        onChange={handleImport}
                    />
                </Box>
            </Popover>
        </TopToolbar>
    )

    return (
        <List actions={<ListActions />} filters={IntentFilter} title={translate('resources.response.form.list')} bulkActionButtons={false} exporter={false} pagination={<IntentPaginator/>} perPage={15}>
            <Datagrid>
                <TextField label="id" source="id"/>
                <TextField label={translate('resources.response.fields.response_code')} source="response_code"/>
                <TextField label={translate('resources.response.fields.content')} source="content"/>
                <TextField label={translate('resources.response.fields.bot_channel')} source="bot_channel"/>
                <TextField label={translate('resources.response.fields.bot_language')} source="bot_language"/>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default IntentList;
