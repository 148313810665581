import { Box, IconButton, Input, Divider, Avatar, Paper, Typography, ToggleButton } from '@mui/material';
import {
    useState,
    useEffect,
    useContext
} from 'react'
import ConversationContext from '../../context/ConversationContext';
import Stack from '@mui/material/Stack';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 16,
    },
});

  
export default function CountdownTimer() {
    const [remainingTime, setRemainingTime] = useState(0);
    const [expired, setExpired] = useState(false);

    const { selectedCreatedAt, selectedSenderId }: any = useContext(ConversationContext)
    
    useEffect(() => {
        if (remainingTime < 0) {
            setExpired(true)
        } else {
            setExpired(false);
        }
    }, [selectedSenderId, remainingTime]);

    useEffect(() => {
        if (!selectedCreatedAt) return ;
        
        const secondsIn24Hours = 24 * 60 * 60; // number of seconds in 24 hours
        const futureEpochTime = selectedCreatedAt + secondsIn24Hours; // epoch time after 24 hours
        const targetTime = new Date(futureEpochTime * 1000); // convert epoch time to date object
        const currentTime = new Date();
        const difference = targetTime.getTime() - currentTime.getTime();
        if (difference < 0) {
            setRemainingTime(difference)
        } else {
            setRemainingTime(difference);
        }
    }, [selectedSenderId]);
    
    useEffect(() => {
        if (!selectedCreatedAt) return ;
        const interval = setInterval(() => {
            const secondsIn24Hours = 24 * 60 * 60; // number of seconds in 24 hours
            const futureEpochTime = selectedCreatedAt + secondsIn24Hours; // epoch time after 24 hours
            const targetTime = new Date(futureEpochTime * 1000); // convert epoch time to date object
            const currentTime = new Date();
            const difference = targetTime.getTime() - currentTime.getTime();
            if (difference < 0) {
                setRemainingTime(difference)
            } else {
                setRemainingTime(difference);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [selectedSenderId]);

    function formatTime(time: any) {
        const seconds = Math.floor(time / 1000) % 60;
        const minutes = Math.floor(time / 1000 / 60) % 60;
        const hours = Math.floor(time / 1000 / 60 / 60) % 24;
        const days = Math.floor(time / 1000 / 60 / 60 / 24);
        return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    }

    return (
        <ThemeProvider theme={theme}>
            {
                expired === false &&
                <Stack spacing={2} direction="row" >
                    <Typography style={{color: '6c757d'}} variant="body2">Remaining</Typography>
                    <Typography variant="body2">{formatTime(remainingTime)}</Typography>
                </Stack>
            }
        </ThemeProvider>
    );
}