import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ResponseList from './response-list';
import ResponseShow from './response-show';
import ResponseEdit from './response-edit';
import ResponseCreate from './response-create';

export default {
    create: ResponseCreate,
    list: ResponseList,
    show: ResponseShow,
    edit: ResponseEdit,
    icon: QuestionAnswerIcon,
}