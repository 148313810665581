import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom"
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEditController, Edit, Toolbar, ShowButton,DeleteButton, SaveButton, useTranslate,TopToolbar, sanitizeListRestProps, useNotify,useRefresh, useRedirect, BooleanInput, AutocompleteInput, PasswordInput, SimpleForm, SelectInput, TextInput, regex, email, required, minLength, maxLength } from 'react-admin';
import dataProvider from '../data-provider'
import VisibilityIcon from '@mui/icons-material/Visibility';

/**
 * Async function to fetch User Role list
 * 
 * @param {*} notify 
 */

const ListActions = (props: any) => {
    const { 
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props

    const navigate = useNavigate()
        return (
                <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                </TopToolbar>
            
        )
}

/**
 * Customized bottom Form Toolbar
 * 
 * @param {} param0 
 */
const BottomToolbar = ({...props}) => {
    const navigate = useNavigate()

    return (
        <Toolbar {...props} >
            <SaveButton disabled={props.invalid || props.pristine}/>
            <Button variant="contained" onClick={() => navigate(`/bot-responses`)} startIcon={<CancelIcon />}>
                Cancel
            </Button>
            <DeleteButton /> 
        </Toolbar>
    )
}


const ResponseEdit = ({...props}) => { 
    const translate = useTranslate()
    const notify = useNotify()
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [roleChoices, setRoleChoices] = React.useState([])
    const validateResponseName = [
        required(translate('resources.response.errors.response_code.required'))
    ]
    const validateLanguage = [required(translate('resources.response.errors.bot_language.required'))
    ]
    const validateUtterance = [
        required(translate('resources.response.errors.text.required'))
    ]

    /**
     * Edit Form submission failure handling
     * @param {} param0 
     */

    const onEditSuccess = ({ data }: any) => {
        notify(`Bot Response "${data.target_name}" successfully updated`)
        redirect('show', props.basePath, data.id);
        refresh();
    };

    /**
     * Method to remove id attribute before submitting for update to avoid server schema validation error
     * @param {*} data 
     */
    const transformRecord = (data: any) => {
        delete data['id']
        return {
            ...data
        }
    }
    return (
        <Edit title={translate('resources.response.form.edit')} 
            transform={transformRecord}
            actions={<ListActions />}
            {...props} >
            <SimpleForm toolbar={<BottomToolbar/>}  >
                <br/>
                <TextInput variant="outlined" source="response_code"
                    validate={validateResponseName}
                    resettable={true}
                    label={translate('resources.response.fields.response_code')} 
                    helperText={translate('resources.response.helperText.response_code')} 
                    fullWidth/>
                <SelectInput variant="outlined" source="bot_language"
                    validate={validateLanguage}
                    resettable={true}
                    label={translate('resources.response.fields.bot_language')} 
                    helperText={translate('resources.response.helperText.bot_language')} 
                    fullWidth
                    choices={[
                        {id: "en", name: "English"}, 
                        {id: 'zh', name: "Mandarin"},
                    ]}/>
                <TextInput variant="outlined" source="content"
                    validate={validateUtterance}
                    resettable={true}
                    label={translate('resources.response.fields.content')} 
                    helperText={translate('resources.response.helperText.content')} 
                    fullWidth multiline/>
                {/* <TextInput variant="outlined" source="remark"
                    // validate={validateRemark}
                    resettable={true}
                    label={translate('resources.response.fields.remark')} 
                    helperText={translate('resources.response.helperText.remark')} 
                    fullWidth/> */}
            </SimpleForm>
        </Edit>
    )
}

export default ResponseEdit