import { useState, useEffect, useContext } from 'react'
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ConversationContext from '../../context/ConversationContext';
import { BACKEND_URL } from '../../constants'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, tagName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      tagName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip() {
    const theme = useTheme();
    const { selectedSenderId, selectedBotChannel, note, setNote, names, setNames, tagName, setTagName }: any = useContext(ConversationContext)
    const [label, setLabel] = useState("Write a note");
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [newTagName, setNewTagName] = useState<string>('');
    const [tagString, setTagString] = useState<string>('');
    const [selectedTagString, setSelectedTagString] = useState<string>('');
    const [tagSaved, setTagSaved] = useState(false);

    useEffect(() => {
      if (tagName && tagName.length > 1) {
          setTagString(tagName.join(","))
      } else {
          setTagString(tagName[0])
      }
      setSelectedTagString(tagString)
    }, [tagName, tagString]);

    function getStyles(name: string, tagName: readonly string[], theme: Theme) {
      return {
        fontWeight:
          tagName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : name === newTagName
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightMedium,
      };
    }

    useEffect(() => {
      setSelectedTags([])
      fetchTagsChoices();
    }, [selectedSenderId, tagSaved]);

    const fetchTagsChoices = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/fetch-tags-choices/${selectedSenderId}`);
        const data = await response.json();
        setNames(data.tags);
        setTagName(data.selected_tags);
        setTagString(data.selected_tags.join(",")) // Set the pre-selected tags from the backend
        setSelectedTags(data.selected_tags)
        setTagSaved(false)
      } catch (error) {
        console.error("Failed to fetch note from backend:", error);
      }
    };

    const handleChange = async (event: SelectChangeEvent<typeof tagName>) => {
      const {
      target: { value },
      } = event;
      setTagName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
      );
      
    };

    useEffect(() => {
      if (tagName && tagString && tagName.length > 0){
        saveTagsToBackend();
      }
    }, [selectedTagString]);
    
    const saveTagsToBackend = async () => {
      const payload = {
        sender_id: selectedSenderId,
        bot_channel: selectedBotChannel,
        note: note,
        tags: selectedTagString
      };
      try { // edit conversation remark
        const response = await fetch(`${BACKEND_URL}/conversation-remark/${selectedSenderId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });
        const data = await response.json();
        if (data === null) { // create conversation remark
          const response = await fetch(`${BACKEND_URL}/conversation-remark`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        setTagSaved(true)
      } catch (error) {
        console.error("Failed to save tag:", error);
      }
    };

    return (
        <div>
          <FormControl sx={{ m: 1, width: 200 }}>
            { tagName === '' && <InputLabel id="demo-multiple-chip-label">tags</InputLabel>}
            <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={tagName}
            // defaultValue={selectedTags} // Set the pre-selected tags from the backend as default value
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Select Tag" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value: any) => (
                    <Chip key={value} label={value} />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}
            >
            {names && names.length > 0 && names.map((name: any) => (
                <MenuItem
                key={name}
                value={name}
                style={getStyles(name, tagName, theme)}
                >
                {name}
                </MenuItem>
            ))}
            </Select>
            <br/>
            <OutlinedInput
              id="new-tag-input"
              value={newTagName}
              onChange={(event) => setNewTagName(event.target.value)}
              placeholder="Enter to Add New Tag"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  setTagName([...tagName, newTagName]);
                  setNewTagName('');
                }
              }}
            />
          </FormControl>
        </div>
    );
}