import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, TextInput } from 'react-admin';

const CustomBotChannelField = ({ source, label }) => {
    const record = useRecordContext();

    return (
        <TextInput disabled label={label} source={source} defaultValue={
            record[source] == 'fb' ? 'Facebook Messenger' : 
            record[source] == 'wa' ? 'WhatsApp' : 
            record[source] == 'webchat' ? 'WebChat' : "Unknown" }/>
    )
}

CustomBotChannelField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
CustomBotChannelField.defaultProps = {
    addLabel: true
}
export default CustomBotChannelField;